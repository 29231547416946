<template>
  <div class="uploadFileWrap">
    <el-dialog
      :title="title"
      @closed="dialogClosed"
      v-model="uploadPriceFlag"
      width="650px"
    >
      <el-upload
        class="upload_kolTag"
        drag
        :before-upload="fileBeforeUpload"
        :on-success="fileOnSuccess"
        :on-error="fileOnError"
        :on-remove="fileRemove"
        :headers="{ token }"
        :accept="accept"
        :limit="limit"
        ref="upload"
        :action="action"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <template #tip>
          <div class="el-upload__tip" style="color: red">
            *只能上传excel文件，且不超过100M
          </div>
          <a class="downloadBtn" @click="fileDownload">
            {{ this.templateName }}
          </a>
        </template>
      </el-upload>
      <template #footer>
        <el-button @click="cancelAddFile">取 消</el-button>
        <el-button :disabled="btnDisable" type="primary" @click="addFileSubmit"
          >确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      uploadPriceFlag: false,
      btnDisable: true,
      accept: ".xls,.xlsx",
      limit: 1,
      filePath: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    uploadAction: {
      type: String,
      default: "",
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    isUploadSuccess: {
      type: Boolean,
      default: false,
    },
    collectionId: {
      type: String,
      default: "",
    },
    templateName: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["token"]),
    action() {
      return process.env.VUE_APP_API_HOST + this.uploadAction;
    },
  },
  methods: {
    fileBeforeUpload(file) {
      const sizeLimit = 1024 * 1024 * 100;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于100M",
          type: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    fileOnSuccess(res) {
      // 上传文件成功，取消禁用按钮
      if (res.code === 9004) {
        this.$message({
          type: "error",
          message: "文件解析失败,请查看模版文件!",
        });
        this.$refs.upload.clearFiles();
      } else if (res.code === 9002) {
        this.$message({
          type: "error",
          message: "文件不存在!",
        });
      } else if (res.code === 0) {
        this.filePath = res.data;
        this.btnDisable = false;
      }
    },
    fileOnError() {
      this.$message.error("上传文件失败!");
    },
    fileRemove() {
      this.btnDisable = true;
    },
    addFileSubmit() {
      this.$emit(
        "submissionUploadPrice",
        this.filePath.replace(/\\/g, "/").split("/").pop()
      );
    },
    cancelAddFile() {
      this.$refs.upload.clearFiles();
      this.uploadPriceFlag = false;
      this.$emit("isShowUpload", false);
    },
    dialogClosed() {
      this.$emit("isShowUpload", false);
      this.btnDisable = true;
    },
    fileDownload() {
      this.$emit("downloadTemplate");
    },
  },
  watch: {
    isShow(val) {
      if (val) {
        this.uploadPriceFlag = true;
      }
    },
    isUploadSuccess(val) {
      if (val) {
        this.$refs.upload.clearFiles();
        this.uploadPriceFlag = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.uploadFileWrap {
  .upload_kolTag {
    text-align: center;
    position: relative;

    .el-upload-list.el-upload-list--text {
      position: absolute;
      top: 54%;
      left: 45%;
      transform: translateX(-50%);

      .el-upload-list__item-name {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }

    .downloadBtn {
      margin-top: 10px;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;

      &:hover {
        color: #409eff;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
      }
    }
  }
}
</style>
