<template>
  <!-- 基础指标 -->
  <div  style="display: flex;justify-content: flex-start;gap:20px;min-height:100%;">
  <baseData style="width:20%;"  :kolMkolId="kolMkolId" :platform="platform"></baseData>
  <!-- 文章趋势 -->
    <div style="width:80%;">
      <articleTrend></articleTrend>
      <!-- 人群画像 -->
      <persona></persona>
      <!-- 合作信息 -->
      <coop v-if="coopRole"></coop>
    </div>
  </div>
</template>

<script setup>
import baseData from "./baseData.vue";
import articleTrend from "./articleTrend.vue";
import persona from "./persona.vue";
import coop from "./coop.vue";
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  kolMkolId: {
    type: String,
    required: true,
  },
  platform: {
    type: String,
    require: true,
  },
});
const coopRole = computed(() => store.state.brandCoop);
store.commit("kolDetails/setKolMkolId", props.kolMkolId);
store.commit("kolDetails/setSite", props.platform);
store.commit("SET_COLLECTIONSITE", props.platform);
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1400px) {
  .breadWrap {
    width: 100% !important;
  }
}
.breadWrap {
  width: 1400px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  .breadIcon {
    font-size: 20px;
    width: 20px;
  }

  .elBreadCrumb {
    flex: 1;
    margin-left: 10px;
  }
}
</style>
