<template>
  <ul class="waterDiv">
    <li class="articleItem" v-for="(item, index) in data" :key="index">
      <a class="articleTitle" :href="item.link" target="_blank">
        {{ item.title != null && item.title != "" ? item.title : item.link }}
      </a>
      <div class="articleFoot">
        <p>
          <span class="date">{{ item.postDate }}</span>
          <span>{{ kolName }}</span>
        </p>
        <p>
          <span v-show="item.exposure">
            {{ platform }}
            {{ item.exposure }}
          </span>
          <span style="margin-left: 5px" v-show="item.reposts != null">
            转发 {{ item.reposts }}
          </span>
          <span style="margin-left: 5px" v-show="item.comments != null">
            评论 {{ item.comments }}
          </span>
          <span style="margin-left: 5px" v-show="item.collection != null">
            收藏 {{ item.collection }}
          </span>
          <span style="margin-left: 10px">
            <svg-icon class-name="iconxihuan" />
            {{ item.likes }}
          </span>
        </p>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const platform = computed(() => {
  return store.state.kolDetails.site === "weibo" ? "阅读" : "阅读";
});

defineProps({
  data: {
    type: Array,
    default() {
      return [];
    },
  },
  kolName: {
    type: String,
    default: "",
  },
});
</script>

<style scoped lang="scss">
.waterDiv {
  height: 385px !important;
  max-height: 385px;
  overflow: auto;
  background-color: #fff;

  .articleItem {
    margin: 10px 20px;
    padding-bottom: 5px;
    font-size: 12px;
    border-bottom: 1px solid #eee;

    .articleTitle {
      display: inline-block;
      line-height: 17px;
      text-decoration: none;
      font-size: $font-size-medium;
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;

      &:hover {
        color: #51a6f0;
      }
    }

    .articleFoot {
      color: $color-sub-text;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date {
        margin-right: 20px;
      }
    }
  }
}
</style>
