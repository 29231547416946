<template>
  <el-row>
    <el-col :span="6">
      <slot name="one"></slot>
    </el-col>
    <el-col :span="6">
      <slot name="two"></slot>
    </el-col>
    <el-col :span="6">
      <slot name="three"></slot>
    </el-col>
    <el-col :span="6">
      <slot name="four"></slot>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "four-item-div",
};
</script>

<style scoped lang="scss">
.el-row {
  .el-col {
    min-height: 30px;
    line-height: 30px;
  }
}
</style>
