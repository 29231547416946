<template>
  <div class="stepBarDiv" ref="chart"></div>
</template>

<script>
/*
 * 传serires的两组data
 * supportData
 * seriesData
 * 柱图颜色
 * percentage格式 普通还是%
 * 文字水印取vuex中的companyName
 * */
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([BarChart, GridComponent, CanvasRenderer]);
import { nextTick } from "vue";

// vue3中的data采用proxy模式，echarts会获取不到事例，不可以把实例写在data中或者专成普通对象
export default {
  name: "step-bar",
  data() {
    return {};
  },
  props: {
    supportData: {
      type: Array,
      default() {
        return [0, 900, 1000, 1200, 1500, 1900];
      },
    },
    seriesData: {
      type: Array,
      default() {
        return [900, 100, 200, 300, 400];
      },
    },
    color: {
      type: String,
      default: "#5696BE",
    },
    percentage: {
      type: String,
      default: "",
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs.chart);
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawColBar() {
      this.myChart.setOption({
        grid: {
          show: false,
          left: 0,
          bottom: -36,
          top: -5,
          right: "30%",
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#eee",
            },
          },
        },
        series: [
          {
            type: "bar",
            stack: "总量",
            itemStyle: {
              opacity: 0,
            },
            cursor: "default",
            barWidth: 20,
            data: this.supportData,
          },
          {
            type: "bar",
            stack: "总量",
            label: {
              show: true,
              position: "right",
              formatter: (params) => {
                if (this.showName) {
                  return params.data.name + this.percentage;
                } else {
                  return this.sliceDecimal(params.data) + this.percentage;
                }
              },
            },
            itemStyle: {
              color: this.color,
            },
            cursor: "default",
            barWidth: 20,
            data: this.seriesData,
          },
        ],
        textStyle: {
          fontFamily: "Microsoft YaHei",
          color: "#111",
        },
      });
    },
    resize() {
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 500);
      });
    },
  },
  watch: {
    seriesData() {
      this.drawColBar();
      this.myChart.resize();
    },
  },
};
</script>

<style scoped lang="scss">
.stepBarDiv {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
