export function formatData(data) {
  data.forEach((v) => {
    v.isShowMoreArticle = false;
    v.isRotate = false;
    v.articleInfoList.forEach((k) => {
      k.title = k.title.replace(/&nbsp;/g, " ");
    });
  });
  return data;
}
