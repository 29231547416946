<template>
  <div id="pieDiv" ref="pieDiv"></div>
</template>

<script setup>
import * as echarts from "echarts";
import { defineProps, watch, reactive } from "vue";
const props = defineProps({
  seriesData: {
    type: Array,
    required: true,
  },
});
const textData = reactive([]);
const drawPie = (seriseData) => {
  var chartDom = document.getElementById("pieDiv");
  var myChart = echarts.init(chartDom);
  const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
  let options = {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        let str = `<p>${params.name}</p>`;
        str += `<p>占比：${params.value.toFixed(2)}%</p>`;
        str += `<p>TGI：${params.data.tgi.toFixed(2)}</p>`;
        return str;
      },
    },
    legend: {
      top: "5%",
      left: "center",
    },
    title: {
      text: "性别分布",
      textStyle: {
        fontWeight: "normal",
        fontSize: 14,
        fontFamily: "Microsoft YaHei",
        color: "#4f4f4f",
      },
      left: 5,
      top: 10,
    },
    graphic: {
      type: "text",
      left: "25%",
      top: "45%",
      style: {
        text: `
          男：${Math.round(textData.value[0].value)}%

          女：${Math.round(textData.value[1].value)}%
        `,
        textAlign: "center",
        fill: "#000",
        width: 30,
        height: 30,
      },
    },
    series: [
      {
        type: "pie",
        radius: ["35%", "60%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            formatter: "",
          },
        },
        labelLine: {
          show: false,
        },
        center: ["50%", "58%"],
        data: seriseData,
      },
    ],
    color: ["#51a6f0", "#A8C9E9"],
  };
  options && unwarp(myChart).setOption(options);
};
watch(
  () => props.seriesData,
  (val) => {
    console.log(val);
    textData.value = val;
    console.log(textData.value);
    drawPie(val);
  },
  {
    deep: true,
  }
);
</script>

<style lang="scss">
#pieDiv {
  flex: 1;
  width: 100%;
  height: 250px;
}
</style>
