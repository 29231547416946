<template>
  <div class="tagShowItem">
    <div class="title_content">
      <span class="classify">{{ classify }}</span>
      <el-checkbox size="mini" v-model="checked" @change="handleChecked">
        多选
      </el-checkbox>
    </div>
    <div>
      <el-tag
        size="small"
        class="tag"
        :class="activeTag('All')"
        @click="chosenAll"
      >
        全部
      </el-tag>
      <el-tag
        v-for="(item, index) in data"
        :key="index"
        size="small"
        class="tag"
        :class="activeTag(item.name)"
        :title="item.tip"
        @click="handleClick(item.name)"
      >
        {{ item.name }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: "tagshow-item",
  data() {
    return {
      value: ["All"],
      checked: false,
    };
  },
  props: {
    // 数据
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    // 显示的标签名
    classify: {
      type: String,
      default: "",
    },
    // 清空数据
    reset: {
      type: Boolean,
      default: false,
    },
    // v-model 清空数据
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["update:modelValue"],
  methods: {
    chosenAll() {
      this.value = ["All"];
    },
    handleClick(el) {
      // 多选
      if (this.checked) {
        if (this.value.includes("All")) {
          this.value = [el];
        } else if (this.value.includes(el)) {
          // 就剩一个值的时候点击无效
          if (this.value.length !== 1) {
            let index = this.value.findIndex((value) => value === el);
            this.value.splice(index, 1);
          }
        } else {
          this.value.push(el);
        }
        // 单选
      } else {
        this.value = [el];
      }
    },
    activeTag(value) {
      if (this.value.includes(value)) {
        return "activeTag";
      } else {
        return "";
      }
    },
    handleChecked(checked) {
      if (!checked && this.value.length !== 1) {
        this.value = [this.value[0]];
      }
    },
  },
  watch: {
    data() {
      this.value = ["All"];
      this.checked = false;
    },
    modelValue(val) {
      this.value = val;
    },
    value: {
      handler(newVal) {
        this.$emit("update:modelValue", newVal);
      },
      deep: true,
    },
    reset(newVal) {
      if (newVal) {
        this.value = ["All"];
        this.checked = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tagShowItem {
  .title_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dcdfe6;
    margin: 10px 0;

    .classify {
      font-size: $font-size-medium;
      font-weight: bold;
    }

    .value {
      color: $color-sub-text;
      margin-left: 20px;
    }
  }

  .tag {
    cursor: pointer;
    margin: 0 5px 5px 0;
    color: $color-sub-text;
    border: 1px solid transparent;
    background-color: transparent;
  }

  .activeTag {
    border: 1px solid rgba(64, 158, 255, 0.2);
    background-color: rgba(64, 158, 255, 0.1);
    color: #409eff;
  }
}
</style>
