<template>
  <el-radio-group
    v-model="listRadio"
    size="mini"
    style="margin-right: 20px"
    @change="handleVersion"
  >
    <el-radio-button :label="1">指标2.0</el-radio-button>
    <el-radio-button :label="0">指标1.0</el-radio-button>
  </el-radio-group>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const listRadio = ref(store.getters.listVersion);

const handleVersion = (val) => {
  listRadio.value = val;
  store.commit("SET_LISTVERSION", val);
};
</script>

<style scoped></style>
