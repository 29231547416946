<template>
  <div class="container">
    <sidebar style="margin-right: 20px" v-if="showSideBar" />
    <div
      style="
        flex: 1;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        border-radius: 10px;
      "
    >
      <filter-com
        :showListVersion="showListVersion"
        :colEmpty="colEmpty"
        :duration="duration"
        :collectionId="collectionId"
        @addToActivity="addToActivity"
      />
      <div
        style="
          flex: 1;
          background-color: #fff;
          display: flex;
          flex-direction: column;
        "
      >
        <div>
          <tool-bar>
            <template #select>
              <new-select-com
                v-model="tagshow"
                :tagshowNumber="filterTags.length"
                @changeInput="query = $event"
                @handleSearch="newSearch"
                @resetData="resetFlag = $event"
              />
            </template>
            <template #imgClick>
              <el-button
                v-show="$route.name === 'CollectDetail'"
                class="noborderBtn"
                type="text"
                @click="imgClick('uploadPrice')"
              >
                <svg-icon
                  class-name="upload"
                  style="font-size: 18px; margin-right: 3px"
                />
                上传价格
              </el-button>
              <el-button
                v-show="$route.name !== 'CollectDetail'"
                class="noborderBtn"
                type="text"
                @click="imgClick('downloadTier')"
              >
                <svg-icon
                  class-name="download"
                  style="font-size: 18px; margin-right: 3px"
                />
                各 Tier 指标中位数下载
              </el-button>
              <el-button
                v-show="$route.name !== 'CollectDetail'"
                class="noborderBtn"
                type="text"
                @click="imgClick('addToCollection')"
              >
                <svg-icon class-name="upload-file" style="font-size: 18px" />
                添加到常用达人
              </el-button>
              <el-button
                v-if="listVersion"
                class="noborderBtn"
                type="text"
                @click="imgClick('touchAnalysisDialogVisible')"
              >
                <svg-icon class-name="analysis" style="font-size: 18px" />
                触达分析
              </el-button>
              <el-button
                class="noborderBtn"
                style="margin-right: 20px"
                type="text"
                @click="imgClick('compareDialogVisible')"
              >
                <svg-icon class-name="compare" style="font-size: 18px" />
                对比分析
              </el-button>
              <el-button
                v-if="$route.path !== '/rankingListV2'"
                class="noborderBtn upLoadInfo"
                type="text"
                size="mini"
                @click="uploadLabelFile"
              >
                <svg-icon class="uploadStyle" class-name="upload"></svg-icon>
                上传KOL标签文件
              </el-button>
            </template>
          </tool-bar>
          <filter-tag
            ref="filterTag"
            v-show="tagshow"
            v-model="resetFlag"
            :labelData="labelData"
            @handleFilter="newSearch"
            @handleDataFilterTag="filterTagArr = $event"
            @handleCoop="handleCoop"
          />
          <div class="tagDiv">
            <el-tag
              closable
              class="ellipsis"
              @close="tagClose(tag)"
              v-for="tag in filterTags"
              :key="tag.name"
              :type="tag.type"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </div>
        <div
          v-show="!indexNodata"
          class="dataDiv"
          v-loading.lock="dataLoading"
          :style="size === 'mini' ? { padding: '5px 0' } : {}"
        >
          <table-v2
            v-if="listVersion"
            :platform="platform"
            :tableData="rankListTableData"
            :benchmarkValue="benchmarkValue"
            @checkEmpty="checkEmpty"
            ref="normalTable"
            @checkboxValueChange="checkboxValueChange"
            @changeSort="sortClick"
            @openDetail="openDetail"
            @reRenderTagShow="reRenderTagShow"
            :allTagOptions="allTagOptions"
            :showHandle="showHandle"
            @openDelKolDialog="openDelKolDialog"
            :isShowCoopInfo="isShowCoopInfo"
            :seedingScoreHeads="seedingScoreHeads"
          />
          <table-v1
            v-else
            :tableData="rankListTableData"
            @checkEmpty="checkEmpty"
            ref="normalTable"
            @checkboxValueChange="checkboxValueChange"
            @changeSort="sortClick"
            @openDetail="openDetail"
            :showHandle="showHandle"
            @openDelKolDialog="openDelKolDialog"
          />
          <!--分页器-->
          <div class="pagination">
            <span class="dataTime">
              * 统计数据时间为：{{
                listVersion === 1 ? duration.replace(/,/g, " ~ ") : month
              }}
            </span>
            <el-pagination
              :current-page="listCurrentPage"
              :page-size="listPageSize"
              :total="listTotalSize"
              @current-change="handleCurrentChange"
              :page-sizes="[20, 50, 100]"
              @size-change="handleSizeChange"
              layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 榜单时，没有数据 -->
        <div
          v-show="indexNodata && $route.name === 'RankingListV2'"
          class="nodata dataDiv"
        >
          <p class="tipP">没有满足条件的数据，请更改搜索/筛选条件，或者:</p>
          <p class="tipP">
            点击
            <span
              style="
                text-decoration: underline;
                color: #5c95c5;
                cursor: pointer;
                vertical-align: unset;
              "
              @click="intoKolCollection"
            >
              这里
            </span>
            前往常用达人提交KOL监测需求
          </p>
        </div>
        <!--常用达人时，没有数据-->
        <div
          v-show="indexNodata && $route.name !== 'RankingListV2'"
          class="nodata dataDiv"
        >
          暂无数据
        </div>
      </div>
    </div>
    <!--添加到常用达人-->
    <el-dialog
      title="添加KOL到常用达人"
      v-model="addToCollection"
      @close="closeAddKol"
      @open="openAddKol"
      :width="1100"
    >
      <el-button
        icon="el-icon-plus"
        type="text"
        @click="creatCollection"
        class="createBtn"
      >
        创建收藏
      </el-button>
      <el-table
        v-loading="loading"
        :data="addToColData"
        highlight-current-row
        @current-change="handleCurrentRow"
      >
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column align="center" prop="desc" label="说明">
        </el-table-column>
        <el-table-column align="center" prop="kolCount" label="KOL数量">
        </el-table-column>
      </el-table>
      <div class="articlePaginationBox">
        <el-pagination
          :current-page="taskCurrentPage"
          :page-size="taskPageSize"
          :total="taskTotal"
          @current-change="handleTaskCurrentChange"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>
        <div class="amount" v-if="colTotal !== 'inf'">
          目前使用: {{ collected }} , 总额度: {{ colTotal }}
        </div>
      </div>
      <template #footer>
        <el-button @click="addCancel">取消</el-button>
        <el-button @click="addConfirm" :disabled="addDisAble" type="primary">
          确定
        </el-button>
      </template>

      <!--创建收藏-->
      <el-dialog
        :width="1000"
        title="创建收藏"
        v-model="innerVisible"
        append-to-body
      >
        <el-form
          ref="ruleForm"
          :rules="colRules"
          :model="form"
          label-width="50px"
        >
          <el-row>
            <el-col :span="10">
              <el-form-item prop="name" label="名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10" :offset="2">
              <el-form-item prop="desc" label="说明">
                <el-input v-model="form.desc"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-button class="btn" type="primary" @click="create"
                >立即创建
              </el-button>
              <el-button class="btn reset" @click="resetForm">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </el-dialog>
    <!-- 触达分析 -->
    <el-dialog
      v-model="touchAnalysisDialogVisible"
      width="75%"
      title="KOL累积触达分析"
      @open="touchAnalysisDialogOpen"
      @close="
        loading = true;
        noData = false;
      "
      custom-class="normalDialog"
      destroy-on-close
    >
      <div>累计触达总人数：{{ touchAnalysisData.uvSum }}</div>
      <touch-analysis
        v-if="!noData"
        :touchAnalysisData="touchAnalysisData"
        :loading="loading"
        :site="platform"
      />
      <div class="nodata" v-else>没有数据</div>
    </el-dialog>
    <!--对比分析-->
    <el-dialog
      v-model="compareDialogVisible"
      width="75%"
      title="KOL比较一览表"
      @open="compareDialogOpen"
      @close="
        loading = true;
        noData = false;
      "
      custom-class="normalDialog"
    >
      <table-com :tableData="compareData" :loading="loading" v-if="!noData" />
      <div class="nodata" v-else>没有数据</div>
    </el-dialog>
    <!--错误提示-->
    <error-dialog
      v-model="errorDialogVisible"
      @confirmClick="errorDialogVisible = false"
    >
      <p>{{ errorDialogText }}</p>
    </error-dialog>
    <!--删除kol-->
    <el-dialog title="警告" v-model="delKolDialogVisible" width="30%">
      <p>确定要删除此KOL吗？</p>
      <template #footer>
        <el-button @click="delKolDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="realDel">确 定</el-button>
      </template>
    </el-dialog>
    <!-- 上传 -->
    <el-dialog title="上传KOL标签文件" v-model="uploadFlag" width="650px">
      <el-upload
        class="upload_kolTag"
        drag
        :before-upload="fileBeforeUpload"
        :on-success="fileOnSuccess"
        :on-error="fileOnError"
        :on-remove="fileRemove"
        :headers="{ token }"
        :accept="accept"
        :limit="limit"
        ref="upload"
        :action="uploadAction"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <template #tip>
          <div class="el-upload__tip" style="color: red">
            *只能上传excel文件，且不超过100M
          </div>
          <a class="downloadBtn" @click="fileDownload"> 点击下载KOL标签模版 </a>
        </template>
      </el-upload>
      <template #footer>
        <el-button @click="cancelAddFile">取 消</el-button>
        <el-button :disabled="btnDisable" type="primary" @click="addFileSubmit"
          >确 定
        </el-button>
      </template>
    </el-dialog>
    <el-dialog title="上传价格" v-model="uploadPriceFlag" width="650px">
      <el-upload
        class="upload_kolTag"
        drag
        :before-upload="fileBeforeUpload"
        :on-success="fileOnSuccess"
        :on-error="fileOnError"
        :on-remove="fileRemove"
        :headers="{ token }"
        :accept="accept"
        :limit="limit"
        ref="upload"
        :action="uploadAction"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <template #tip>
          <div class="el-upload__tip" style="color: red">
            *只能上传excel文件，且不超过100M
          </div>
          <a class="downloadBtn" @click="fileDownload"> 点击下载KOL标签模版 </a>
        </template>
      </el-upload>
      <template #footer>
        <el-button @click="cancelAddFile">取 消</el-button>
        <el-button :disabled="btnDisable" type="primary" @click="addFileSubmit"
          >确 定
        </el-button>
      </template>
    </el-dialog>
    <uploadFile
      @isShowUpload="isShowUpload"
      @submissionUploadPrice="submissionUploadPrice"
      :isShow="uploadPrice"
      :isUploadSuccess="isUploadSuccess"
      @downloadTemplate="downloadTemplate"
      templateName="点击下载KOL价格模板"
      uploadAction="/collection/kol/price"
      title="上传价格"
    ></uploadFile>
    <!--详情弹窗-->
    <el-dialog
      v-model="dialogVisible"
      fullscreen
      :show-close="false"
      destroy-on-close
    >
      <el-container class="dialog_container">
        <el-header height="90px">
          <header-com :key="key"></header-com>
        </el-header>
        <el-main>
          <kol-details
            ref="kolDetails"
            :kolMkolId="mKolId"
            :platform="platform"
          ></kol-details>
        </el-main>
        <el-footer height="30px">
          <!-- <common-footer /> -->
        </el-footer>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import Sidebar from "@/components/common/socialPlatform";
import filterCom from "@/components/rankingList/filter";
import toolBar from "@/components/toolBar";
import newSelectCom from "@/components/rankingList/colNewSelectCom";
import filterTag from "@/components/filterTag/index";
import touchAnalysis from "@/components/touchAnalysis/touchAnalysis";
import tableCom from "@/components/tableCom";
import errorDialog from "@/components/errorDialog";
import tableV1 from "@/components/list/table1.0";
import tableV2 from "@/components/list/table2.0";
import kolDetails from "@/views/kolDetails";
import headerCom from "@/views/Home/header/header";
import CommonFooter from "@/components/common/footer/footer";
import uploadFile from "@/components/uploadFile";

import { mapGetters, mapMutations, mapState } from "vuex";
import {
  touchAnalysisInitData,
  getDuration,
  getBenchmark,
  getKolList,
  getKolListV2,
  personalAnalysis,
  kolTouchAnalysis,
  getKolLabel,
  downloadKolMedian,
} from "@/api/rankingList";
import { getCollectionInfo } from "@/api/collection";
import {
  getUserCollect,
  submitKol,
  creatCollect,
  getAmount,
  delHaveKol,
  getHaveKol,
  getHaveKolV2,
  downloadKolLabelTemplate,
  downloadPriceTemplate,
  submitFileUpload,
  submitPriceUpload,
} from "@/api/collection";
import { nextTick } from "vue";

export default {
  name: "RankingListV2",
  data() {
    let checkColName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入收藏夹名称"));
      }
      const regular = new RegExp(
        "[`·~!@#$^&*()=+|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】 ‘；：”“'。，、？]"
      );
      if (regular.test(value) || value.replace(/\s+/g, "") === "") {
        callback(new Error("任务名称只能包含数字、字母、中文汉字、_、-"));
      } else {
        this.form.name = value.trim();
        callback();
      }
    };
    return {
      rankListTableData: [],
      checkboxValue: [],
      isDataEmpty: false,
      listCurrentPage: 1,
      listPageSize: 20,
      listTotalSize: 0,
      dataLoading: true,
      indexNodata: false,

      // 添加到常用达人
      addToCollection: false,
      taskPageSize: 5,
      taskCurrentPage: 1,
      taskTotal: 0,
      addToColData: [],
      colTotal: 0,
      collected: 0,
      addDisAble: true,
      currentRowId: 0,
      innerVisible: false,

      sort: 0,
      sortColumn: "activeViewerNum",
      loading: true,
      noData: false,

      touchAnalysisDialogVisible: false,
      touchAnalysisData: {},

      errorDialogVisible: false,
      errorDialogText: "",

      compareDialogVisible: false,
      compareData: {},
      tags: {
        categories: [], // 标签
        tiers: [], // tier，榜单1.0没有tier
        labels: [],
        filter: {}, // 其他筛选,
        coopBrand: "",
        coopCampaignTypes: [],
      },
      coopBrand: "",
      coopCampaignTypes: [],
      // 其他筛选 数据筛选的一维数组集合
      filterTagArr: [],
      tagshow: false,
      query: "",
      resetFlag: false,

      form: {
        name: "",
        desc: "",
      },
      colRules: {
        name: [
          { required: true, validator: checkColName, trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        desc: [{ max: 500, message: "长度小于500字符", trigger: "blur" }],
      },

      mKolId: "",

      // 记录选中的kol
      checkedKolList: [],

      duration: "",
      benchmarkValue: {},
      kolIndexs: [],
      // 删除kol
      delKolDialogVisible: false,
      delKolId: null,
      labelData: [],
      allTagOptions: [],
      uploadFlag: false,
      uploadPriceFlag: false,
      uploadAction: process.env.VUE_APP_API_HOST + "/kol/label/file",
      btnDisable: true,
      accept: ".xls,.xlsx",
      limit: 1,
      filePath: "",
      // 记录列表参数
      backParams: null,
      dialogVisible: false,
      // 上传价格
      uploadPrice: false,
      isUploadSuccess: false,
      // 是否显示合作品牌信息
      isShowCoopInfo: true,
      seedingScoreHeads: [],
    };
  },
  computed: {
    ...mapState(["dialogClose"]),
    ...mapGetters([
      "month",
      "siteValue",
      "categorySeg",
      "orderRule",
      "listVersion",
      "colSiteValue",
      "colCategorySeg",
      "colOrderRule",
      "token",
      "menuList",
    ]),
    // ...mapGetters(["menuList"]),
    seg() {
      return this.$route.name === "RankingListV2"
        ? this.categorySeg
        : this.colCategorySeg;
    },
    platform() {
      return this.$route.name === "RankingListV2"
        ? this.siteValue
        : this.colSiteValue;
    },
    keyword() {
      return this.query.trim().replace(/\s+/g, " ");
    },
    params() {
      let tempObj = {
        seg: this.seg,
        platform: this.platform, // 平台
        type: "month",
        tag1: this.tags.categories.includes("All") ? [] : this.tags.categories,
        filter: this.tags.filter,
        page: this.listCurrentPage,
        kolNames: this.keyword, // 搜索
        month: this.month, // 月份,
        size: this.listPageSize,
        sortColumn: this.sortColumn,
        sortDirection: this.sort === 0 ? "desc" : "asc",
      };
      if (this.$route.name !== "RankingListV2") {
        tempObj.collectionId = this.collectionId;
      }
      return tempObj;
    },
    params2() {
      let tempObj = {
        seg: this.seg,
        platform: this.platform, // 平台
        categories: this.tags.categories.includes("All")
          ? []
          : this.tags.categories,
        tiers: this.tags.tiers.includes("All") ? [] : this.tags.tiers,
        labels: this.tags.labels.includes("All") ? [] : this.tags.labels,
        filter: this.tags.filter,
        page: this.listCurrentPage,
        kolNames: this.keyword, // 搜索
        month: this.month, // 月份,
        size: this.listPageSize,
        sortColumn: this.sortColumn,
        sortDirection: this.sort === 0 ? "desc" : "asc",
        duration: this.duration,
        coopBrand: this.coopBrand === "" ? "" : this.coopBrand,
        coopCampaignTypes:
          this.coopCampaignTypes?.length === 0 ? [] : this.coopCampaignTypes,
      };
      if (this.$route.name !== "RankingListV2") {
        tempObj.collectionId = this.collectionId;
      }
      return tempObj;
    },
    // 将所有的filter以tag的形式展现
    filterTags() {
      let tempArr = [];
      // 分类
      if (
        this.tags.categories &&
        this.tags.categories.length !== 0 &&
        this.tags.categories[0] !== "All"
      ) {
        tempArr.push({
          name: `分类： ${this.tags.categories}`,
          type: "success",
        });
      }
      // 级别
      if (
        this.listVersion === 1 &&
        this.tags.tiers &&
        this.tags.tiers.length !== 0 &&
        this.tags.tiers[0] !== "All"
      ) {
        tempArr.push({
          name: `级别： ${this.tags.tiers}`,
          type: "warning",
        });
      }
      // 标签
      if (
        this.listVersion === 1 &&
        this.tags.labels &&
        this.tags.labels[0] !== "All" &&
        this.tags.labels.length !== 0
      ) {
        tempArr.push({
          name: `标签： ${this.tags.labels}`,
          type: "info",
        });
      }
      // 合作品牌筛选
      if (this.listVersion === 1 && this.coopBrand !== "") {
        tempArr.push({
          name: `合作品牌： ${this.coopBrand}`,
          type: "coopBrand",
        });
      }
      // 合作类型
      if (
        this.listVersion === 1 &&
        this.coopCampaignTypes &&
        this.coopCampaignTypes.length !== 0
      ) {
        tempArr.push({
          name: `合作类型： ${this.coopCampaignTypes.join(",")}`,
          type: "coopCampaignTypes",
        });
      }
      // 其他筛选 数据筛选
      tempArr = [
        ...tempArr,
        ...this.filterTagArr.map(({ label, filterKeys }) => ({
          name: label,
          type: "",
          filterKeys,
        })),
      ];
      return tempArr;
    },
  },
  props: {
    showSideBar: {
      type: Boolean,
      default: true,
    },
    showListVersion: {
      type: Boolean,
      default: false,
    },
    showHandle: {
      type: Boolean,
      default: false,
    },
    collectionId: {
      default: 0,
    },
    size: {
      type: String,
      default: "normal",
    },
    colEmpty: {
      type: Boolean,
      default: false,
    },
  },
  beforeRouteLeave(to, from, next) {
    if(this.dialogVisible){
      //弹窗打开，阻止跳转，默认关闭弹层
      this.dialogVisible = false
      this.setIsAddClass(false);
      next(false);
    } else{
      this.setIsAddClass(false);
      this.setListVersion(1);
      next();
    }
  },
  async mounted() {
    if (this.listVersion === 1) {
      this.sortColumn = "activeViewerNum";
      await this.getDuration();
      await this.getBenchmark();
      // 获取labledata会导致刷新列表
      await this.getKolLabel();
    } else {
      this.sortColumn = "muv";
    }
    // await this.getAmount();
    this.setIsHighLight(true);
  },
  methods: {
    ...mapMutations({
      setListVersion: "SET_LISTVERSION",
      setSites: "SET_SITES",
      setSiteValue: "SET_SITEVALUE",
      setColSiteValue: "SET_COLSITEVALUE",
      setIsHighLight: "SET_ISHIGHLIGHT",
      setListSortLabel: "SET_LISTSORTLABEL",
      setDetailsToRankList: "SET_DETAILSTORANKLIST",
      setIsAddClass: "SET_ISADDCLASS",
      setDialogClose: "SET_DIALOGCLOSE",
    }),
    isShowUpload() {
      this.uploadPrice = false;
    },
    async submissionUploadPrice(param) {
      let { code } = await submitPriceUpload({
        fileName: param,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.isUploadSuccess = true;
        setTimeout(() => {
          this.isUploadSuccess = false;
        }, 1000);
      } else {
        this.$message.error("上传失败,请联系管理员");
      }
    },
    downloadTemplate() {
      downloadPriceTemplate(
        {
          collectionId: this.collectionId,
        },
        "KOL价格模版.xlsx"
      );
    },
    fileDownload() {
      downloadKolLabelTemplate(
        {
          collectionId: this.collectionId,
        },
        "KOL标签模版.xlsx"
      );
    },
    uploadLabelFile() {
      this.uploadFlag = true;
    },
    fileBeforeUpload(file) {
      const sizeLimit = 1024 * 1024 * 100;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于100M",
          type: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    fileOnSuccess(res) {
      // 上传文件成功，取消禁用按钮
      if (res.code === 9004) {
        this.$message({
          type: "error",
          message: "文件解析失败,请查看模版文件!",
        });
        this.$refs.upload.clearFiles();
      } else if (res.code === 0) {
        this.filePath = res.data;
        this.btnDisable = false;
      }

    },
    fileOnError() {
      this.$message.error("上传文件失败!");
    },
    fileRemove() {
      this.btnDisable = true;
    },
    async addFileSubmit() {
      let { code } = await submitFileUpload({
        fileName: this.filePath.replace(/\\/g, "/").split("/").pop(),
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "上传成功",
        });
      } else {
        this.$message.error("上传失败,请联系管理员");
      }
      setTimeout(() => {
        this.uploadFlag = false;
        this.$refs.upload.clearFiles();
      }, 1000);
    },
    cancelAddFile() {
      this.$refs.upload.clearFiles();
      this.uploadFlag = false;
    },
    async getKolLabel() {
      let { code, data } = await getKolLabel();
      if (code === 0 && data.length) {
        // 去重
        data = Array.from(new Set(data));
        this.labelData = data.map((name) => ({
          name,
          tip: "",
        }));
        // kol展开后的tagOptions
        this.allTagOptions = data.map((v) => ({
          label: v,
          value: v,
        }));
      } else {
        this.labelData = [];
        this.allTagOptions = [];
      }
    },
    async getAmount() {
      const { code, data } = await getAmount();
      if (code === 0) {
        this.colTotal = data.total;
        this.collected = data.collected;
      }
    },
    async initData() {
      this.dataLoading = true;
      let res = null;
      if (this.listVersion === 1) {
        if (this.$route.name === "RankingListV2") {
          res = await getKolListV2(this.params2);
        } else {
          res = await getHaveKolV2(this.params2);
        }
      } else {
        if (this.$route.name === "RankingListV2") {
          res = await getKolList(this.params);
        } else {
          res = await getHaveKol(this.params);
        }
      }
      if (res?.code === 0 && (res.data.rows || res.data.table)) {
        let tableData;
        if (this.listVersion === 1) {
          this.seedingScoreHeads = res.data.heads;
          tableData = res.data.rows;
        } else {
          tableData = res.data.table;
        }
        if (JSON.stringify(tableData) === "[]") {
          this.indexNodata = true;
          this.rankListTableData = [];
          this.listTotalSize = 0;
        } else {
          this.indexNodata = false;
          // 有勾选的kol
          if (this.checkedKolList.length !== 0) {
            // 将勾选的kol插入头部
            tableData.unshift(...this.checkedKolList);
            // 数组中去除重复对象
            const obj = {};
            tableData = tableData.reduce((arr, item) => {
              obj[item.mKolid]
                ? ""
                : (obj[item.mKolid] = true && arr.push(item));
              return arr;
            }, []);
            // this.listTotalSize = tableData.length;
          } else {
            this.checkedKolList = [];
            // this.listTotalSize = res.data.total;
          }
          this.listTotalSize = res.data.total;
          this.rankListTableData = tableData;
        }
      } else {
        this.indexNodata = true;
        this.rankListTableData = [];
        this.listTotalSize = 0;
      }
      setTimeout(() => {
        this.dataLoading = false;
      }, 50);
    },
    // 搜索和其他筛选, 切换平台也会触发一下
    // 有data表示是filter
    // 没有data表示是搜索
    newSearch(data) {
      if (data) {
        this.tags = data;
      }
      this.listCurrentPage = 1;
      if (this.listVersion === 0 && this.platform === "douyin") {
        this.sortColumn = "muv_fans";
      }
      this.initData();
    },
    handleCoop(coopData) {
      const { coopBrand, coopCampaignType } = coopData;
      this.coopBrand = coopBrand;
      this.coopCampaignTypes = coopCampaignType;
    },
    checkEmpty(flag) {
      this.isDataEmpty = flag;
    },
    sortByActiveViewerNumDesc(array) {
      return array.sort((a, b) => b.activeViewerNum - a.activeViewerNum);
    },

    // 新的赋值checkboxValue方法
    checkboxValueChange(kolList, bool) {
      const newKolList = this.sortByActiveViewerNumDesc(kolList)
      console.log(newKolList,'newKolList')
      // 指标2.0特殊格式
      const kolIndexArr = [];
      this.checkboxValue = []
      newKolList.forEach((v) => {
        kolIndexArr.push(`${v.mKolid}|${v.activeViewerNum}`);
        this.checkboxValue.push(v.mKolid)
      });
      this.kolIndexs = kolIndexArr.join();
      this.checkedKolList = newKolList;
      // 去除超过limit提示
      // if (bool) {
      //   this.errorDialogVisible = true;
      //   this.errorDialogText = `最多选择${kolList.length}KOL`;
      // }
    },
    sortClick(col, label) {
      if (col === this.sortColumn) {
        this.sort = 1 - this.sort;
      } else {
        this.sortColumn = col;
        this.sort = 0;
      }
      this.listCurrentPage = 1;
      this.setListSortLabel(this.sort === 0 ? `${label}降序` : `${label}升序`);
      // 发送排序的请求
      this.initData();
    },
    openDetail(mKolId, name) {
      this.mKolId = mKolId;
      this.$storage.setItem("kolName", name);
      this.$storage.setItem("returnPath", this.$route.fullPath);
      this.dialogVisible = true;
      this.setIsAddClass(true);
    },
    reRenderTagShow() {
      // 重新获取label，更新 tagShow 组件
      this.getKolLabel();
    },
    handleCurrentChange(val) {
      this.listCurrentPage = val;
      this.initData();
    },
    handleSizeChange(val) {
      this.listPageSize = val;
      this.initData();
    },
    // 没找到数据点击提交
    intoKolCollection() {
      this.$router.push({ name: "KolCollection" });
    },
    async touchAnalysisDialogOpen() {
      this.loading = true;
      this.touchAnalysisData = {};
      let params = {
        seg: "32_1",
        month: this.month,
        kolIds: this.checkboxValue,
        site: this.platform,
      };
      let params2 = {
        seg: "32_1",
        month: this.month,
        kolIds: this.checkboxValue.join(","),
        site: this.platform,
        duration: this.duration,
        kolIndexs: this.kolIndexs,
      };
      let res = null;
      if (this.listVersion === 1) {
        res = await kolTouchAnalysis(params2);
      } else {
        res = await touchAnalysisInitData(params);
      }
      if (res.code === 0) {
        if (JSON.stringify(res.data) === "{}") {
          this.noData = true;
        } else {
          this.noData = false;
          this.touchAnalysisData = res.data;
        }
      } else {
        this.noData = false;
        if (res.code === 123) this.$message.error("请联系管理员!");
      }
      this.loading = false;
    },
    imgClick(param) {
      if (param === "touchAnalysisDialogVisible") {
        if (this.isDataEmpty) {
          this.$message({
            message: '存在 "月UV" 为0的KOL，分析结果无意义，请重新选择',
            type: "info",
          });
          return false;
        } else if (!this.checkboxValue.length) {
          // 至少选择一个
          this.errorDialogVisible = true;
          this.errorDialogText = "至少选择一个KOL";
        } else {
          this[param] = true;
        }
      } else if (param === "uploadPrice") {
        this[param] = true;
      } else if (param === "downloadTier") {
        this.downloadTierFunc();
      } else if (!this.checkboxValue.length) {
        // 至少选择一个
        this.errorDialogVisible = true;
        this.errorDialogText = "至少选择一个KOL";
      } else {
        this[param] = true;
      }
    },
    async downloadTierFunc() {
      this.$message({
        type: "info",
        message: "数据正在解析，请耐心等待！",
      });
      let res = await downloadKolMedian("", "各Tier指标中位数.xlsx");
      if (res.code !== 123) {
        this.$message({
          type: "success",
          message: "文件正在下载！",
        });
      }
    },
    async openAddKol() {
      this.loading = true;
      let params = {
        search: "",
        sortColumn: "create_time",
        sortDirection: "desc",
        page: this.taskCurrentPage,
        size: this.taskPageSize,
      };
      let {
        code,
        data: { table, total },
      } = await getUserCollect(params);
      if (code === 0) {
        this.addToColData = table;
        this.addToColData.forEach((v) => {
          v.kolCount = Object.values(v.kolNum).reduce(
            (total, curr) => total + curr
          );
        });
        this.taskTotal = total;
      }
      this.loading = false;
    },
    closeAddKol() {
      this.taskCurrentPage = 1;
      this.addDisAble = true;
    },
    creatCollection() {
      this.innerVisible = true;
    },
    create() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let { code } = await creatCollect(this.form);
          if (code === 0) {
            this.$message({
              message: "创建收藏夹成功！",
              type: "success",
            });
            this.loading = true;
            this.innerVisible = false;
            this.addDisAble = true;
            await this.openAddKol();
          } else {
            this.$message.error("创建失败!");
          }
        } else {
          return false;
        }
      });
      this.form.desc = ''
      this.form.name = ''
    },
    resetForm() {
      nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },
    handleCurrentRow(currentRow) {
      if (currentRow === null) {
        return false;
      }
      nextTick(() => {
        setTimeout(() => {
          this.currentRowId = currentRow.id;
        }, 100);
      });
    },
    handleTaskCurrentChange(val) {
      this.taskCurrentPage = val;
      this.openAddKol();
    },
    async addConfirm() {
      if (this.checkboxValue.length === 0) {
        this.$message.info("请选择需要添加的KOL");
      } else if (this.currentRowId !== 0) {
        let addKolParams = {
          collectionId: this.currentRowId,
          kols: this.checkboxValue,
          nonKols: [],
        };
        let { code } = await submitKol(addKolParams);
        if (code === 0) {
          this.$message.success("添加KOL成功!");
          this.addCancel();
        }
      }
    },
    addCancel() {
      this.addToCollection = false;
      this.addDisAble = true;
      this.currentRowId = 0;
    },
    async compareDialogOpen() {
      this.loading = true;
      let params = {
        duration: this.duration,
        month: this.month,
        kolIds: this.checkboxValue.join(","),
      };
      let { code, data } = await personalAnalysis(params);
      if (code === 0) {
        if (JSON.stringify(data) === "{}") {
          this.noData = true;
          return;
        }
        for (let item of Object.values(data)) {
          if (JSON.stringify(item) === "{}") {
            this.noData = true;
            return;
          }
        }
        this.noData = false;
        nextTick(() => {
          this.compareData = data;
        });
      } else {
        this.noData = true;
      }
      this.loading = false;
    },
    async getDuration() {
      const { code, data } = await getDuration();
      if (code === 0) {
        this.duration = data;
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    async getBenchmark() {
      const { code, data } = await getBenchmark({
        platform: this.platform,
        duration: this.duration,
      });
      if (code === 0) {
        this.benchmarkValue =
          data === null
            ? {
              contentQualityIndex: null,
              effectiveTrafficIndex: null,
              fansActiveIndex: null,
              waterIndex: null,
            }
            : data;
      }
    },
    tagClose(tag) {
      // 分类
      if (tag.type === "success") {
        this.$refs.filterTag.delCategoryData();
      }
      // 级别
      else if (tag.type === "warning") {
        this.$refs.filterTag.delTierData();
      }
      // 标签
      else if (tag.type === "info") {
        this.$refs.filterTag.delLabelData();
      }
      // 合作信息删除
      else if (tag.type === "coopBrand") {
        this.coopBrand = "";
        this.$refs.filterTag.delCoopBrand();
      } else if (tag.type === "coopCampaignTypes") {
        this.coopCampaignTypes = [];
        this.$refs.filterTag.delCampaignTypes();
      }
      // 其他筛选 数据筛选
      // 需一一删除
      else {
        const i = this.filterTagArr.findIndex(
          ({ label }) => label === tag.name
        );
        // 删除tah展示
        this.filterTagArr.splice(i, 1);
        // 删除提交数据
        tag.filterKeys.forEach((k) => delete this.tags.filter[k]);
        // 删除filter组件内部数据以便下次提交保持一致
        this.$refs.filterTag.deleteFilterData(tag.filterKeys);
        this.initData();
      }
    },
    async handleListType() {
      this.tags = {
        categories: [], // 标签
        tiers: [], // tier，榜单1.0没有tier
        labels: [],
        filter: {}, // 其他筛选,
      };
      // 其他筛选 数据筛选的一维数组集合
      this.filterTagArr = [];
      this.tagshow = false;
      this.query = "";
      this.resetFlag = false;
      this.checkedKolList = [];
      this.checkboxValue = [];
      this.$refs.normalTable.checkBoxList = [];
      this.listCurrentPage = 1;
      this.setIsHighLight(true);
      if (this.listVersion === 1) {
        this.sortColumn = "activeViewerNum";
        await this.getDuration();
        await this.getBenchmark();
      } else {
        switch (this.platform) {
          case "wechat":
            this.sortColumn = "muv";
            break;
          case "weibo":
            this.sortColumn = "mact";
            break;
          case "douyin":
            this.sortColumn = "muv_fans";
            break;
          case "xiaohongshu":
            this.sortColumn = "muv";
            break;
          case "bilibili":
            this.sortColumn = "muv";
            break;
          case "kuaishou":
            this.sortColumn = "muv";
            break;
        }
      }
      await this.getAmount();
    },
    // 删除kol，针对常用达人
    openDelKolDialog(id) {
      this.delKolDialogVisible = true;
      this.delKolId = id;
    },
    async realDel() {
      this.delKolDialogVisible = false;
      let { code } = await delHaveKol({ id: this.delKolId });
      if (code === 0) {
        let colIndex = this.rankListTableData.findIndex((v) => {
          return v.id === this.delKolId;
        });
        this.rankListTableData.splice(colIndex, 1);
        setTimeout(() => {
          this.$message({
            message: "删除KOL成功!",
            type: "info",
          });
        }, 200);
      }
      await this.getAmount();
      await this.getCollectionInfo();
      await this.initData();
    },
    async getCollectionInfo() {
      let { data } = await getCollectionInfo({
        collectionId: this.collectionId,
      });
      let kolNumber = {
        kolAmount: data.kolAmount,
        nonKolAmount: data.nonKolAmount,
      };
      this.$emit("refreshKolNumber", kolNumber);
    },
    // 追加kol
    addToActivity() {
      if (!this.checkedKolList.length) {
        this.$message({
          message: "请选择kol!",
          type: "warning",
        });
      } else {
        this.$emit("addToActivity", this.checkedKolList);
      }
    },
  },
  watch: {
    menuList(val) {
      if (val) {
        let tempArr = val.find((v) => v.id === 410);
        if (tempArr) {
          let isShowCoopInfo = tempArr.children.find((v) => v.id === 430);
          if (isShowCoopInfo) {
            this.isShowCoopInfo = true;
          } else {
            this.isShowCoopInfo = false;
          }
        }
      }
    },
    seg(val) {
      if (val || val === null) {
        this.checkedKolList = [];
        this.checkboxValue = [];
        this.$refs.normalTable.checkBoxList = [];
        this.listCurrentPage = 1;
        this.initData();
      }
    },
    platform(newVal) {
      this.checkedKolList = [];
      this.checkboxValue = [];
      this.$refs.normalTable.checkBoxList = [];
      // 排序列
      switch (newVal) {
        case "wechat":
          this.sortColumn = "muv";
          break;
        case "weibo":
          this.sortColumn = "mact";
          break;
        case "douyin":
          this.sortColumn = "muv_fans";
          break;
        case "xiaohongshu":
          this.sortColumn = "muv";
          break;
        case "bilibili":
          this.sortColumn = "muv";
          break;
        case "kuaishou":
          this.sortColumn = "muv";
          break;
      }
      if (this.listVersion === 1) {
        this.sortColumn = "activeViewerNum";
        this.getBenchmark();
      }
      this.sort = 0;
    },
    currentRowId(val) {
      if (val !== 0) {
        this.addDisAble = false;
      }
    },
    orderRule(newVal) {
      let arr = newVal.split("_");
      this.sortColumn = arr[0];
      this.sort = +arr[1];
      this.initData();
    },
    colOrderRule(newVal) {
      let arr = newVal.split("_");
      this.sortColumn = arr[0];
      this.sort = +arr[1];
      this.initData();
    },
    listVersion() {
      this.handleListType();
    },
    collectionId() {
      // 常用达人收藏夹变化
      this.checkedKolList = [];
      this.checkboxValue = [];
      this.$refs.normalTable.checkBoxList = [];
      this.listCurrentPage = 1;
      this.initData();
    },
    dialogClose(val) {
      if (val) {
        this.dialogVisible = false;
        this.setIsAddClass(false);
        this.setDialogClose(false);
      }
    },
  },
  components: {
    Sidebar,
    filterCom,
    toolBar,
    newSelectCom,
    filterTag,
    touchAnalysis,
    tableCom,
    errorDialog,
    tableV1,
    tableV2,
    kolDetails,
    headerCom,
    CommonFooter,
    uploadFile,
  },
};
</script>
<style lang="scss">
.container {
  .upload_kolTag {
    text-align: center;
    position: relative;

    .el-upload-list.el-upload-list--text {
      position: absolute;
      top: 54%;
      left: 45%;
      transform: translateX(-50%);

      .el-upload-list__item-name {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }

    .downloadBtn {
      margin-top: 10px;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;

      &:hover {
        color: #409eff;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
      }
    }
  }

  .tagDiv {
    .el-tag .el-tag__close {
      position: absolute;
      top: 8px;
      right: 5px;
    }
  }

  .el-dialog.is-fullscreen .el-dialog__header {
    padding: 0;
  }
  .el-dialog.is-fullscreen .el-dialog__body {
    padding: 0;
    height: 100%;
  }
}
</style>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  display: flex;

  .upLoadInfo {
    margin-right: 0;

    &:hover svg {
      fill: #66b1ff;
    }
  }

  .uploadStyle {
    fill: #888;
    font-size: 16px;
  }

  .tagDiv {
    margin-top: 10px;

    .el-tag {
      max-width: 1100px;
      margin-right: 10px;
      position: relative;
      padding-right: 22px;
    }
  }

  .dataDiv {
    flex: 1;
    margin-top: 5px;
    overflow: hidden;

    .tipP {
      margin-top: 5px;
    }

    .pagination {
      text-align: center;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dataTime {
        font-size: $font-size-medium;
        line-height: 30px;
        color: $color-sub-title;
        padding-left:20px;
      }
    }

    .el-checkbox-group {
      min-height: 100%;
      overflow: auto;

      .el-checkbox {
        display: block;
        line-height: 34px;
        margin: 0;
      }
    }
  }

  .articlePaginationBox {
    text-align: center;
    margin: 20px 0;
    position: relative;
  }

  .amount {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .createBtn {
    position: absolute;
    right: 19px;
    top: 31px;
    font-size: 14px;
  }

  .el-header {
    background-color: transparent;
    color: $color-text;
  }

  .el-main {
    color: $color-text;
    width: 100%;
    margin: 0 auto;
  }

  .el-footer {
    background-color: $color-dialog-background;
    color: $color-text-l;
    line-height: 30px;
    text-align: center;
    border-top: 1px solid #ddd;
  }
}
.dialog_container{
  height: 100%;
  background:#EFF3F4 url("../../assets/images/Background.png") no-repeat top;
  background-size:100% 400px;
}
</style>
