<template>
  <div class="coopWrap" v-loading="coopLoading">
    <el-card>
      <p class="cardTitle">近3月合作品牌</p>
      <el-table :data="coopList" style="width: 100%">
        <el-table-column
          align="center"
          prop="brandName"
          label="合作品牌"
          width="200px"
        >
          <template #default="{ row }">
            <div class="logoWrap">
              <div class="logo">
                <img :src="row.logo" alt="" />
              </div>
              <div class="brandNameClass ellipsis">
                {{ row.brandName }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          align="center"
          prop="coopCount"
          label="合作次数"
        >
          <template #default="{ row }">
            <i
              @click="iconClick(row)"
              v-if="row.coopCount > 1"
              :class="[
                'el-icon-arrow-right',
                'iclass',
                { rotateClass: row.isRotate },
              ]"
            ></i>
            {{ row.coopCount }}
          </template>
        </el-table-column>
        <el-table-column
          class="centerClass"
          align="left"
          prop="articleInfoList"
        >
          <template #header>
            <p class="headerClass">投放文章/视频</p>
          </template>
          <template #default="{ row }">
            <p
              class="ellipsis pStyle"
              v-if="articleInfoListFlag(row.articleInfoList)"
            >
              <a
                target="_blank"
                class="aLinkClass"
                :href="row.articleInfoList[0].link"
              >
                {{ row.articleInfoList[0].title }}
              </a>
            </p>
            <template
              v-if="
                row.isShowMoreArticle &&
                row.articleInfoList &&
                row.articleInfoList.length > 1
              "
            >
              <p
                class="ellipsis pStyle"
                :key="index"
                v-for="(item, index) in row.articleInfoList.slice(1)"
              >
                <a target="_blank" class="aLinkClass" :href="item.link">{{
                  item.title
                }}</a>
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="200px"
          prop="postDate"
          label="投放日期"
        >
          <template #default="{ row }">
            <p
              class="ellipsis pClass"
              v-if="articleInfoListFlag(row.articleInfoList)"
            >
              {{ row.articleInfoList[0].postDate }}
            </p>
            <template
              v-if="
                row.isShowMoreArticle &&
                row.articleInfoList &&
                row.articleInfoList.length > 1
              "
            >
              <template
                class="ellipsis"
                :key="index"
                v-for="(item, index) in row.articleInfoList.slice(1)"
              >
                <p class="pClass">
                  {{ item.postDate }}
                </p>
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          width="150px"
          align="center"
          prop="exposure"
          label="曝光UV"
        >
          <template #default="{ row }">
            <p class="pClass" v-if="articleInfoListFlag(row.articleInfoList)">
              {{
                row.articleInfoList[0].like === null
                  ? "--"
                  : sliceDecimal(row.articleInfoList[0].exposure)
              }}
            </p>
            <template
              v-if="
                row.isShowMoreArticle &&
                row.articleInfoList &&
                row.articleInfoList.length > 1
              "
            >
              <p
                class="pClass"
                :key="index"
                v-for="(item, index) in row.articleInfoList.slice(1)"
              >
                <span>{{
                  item.exposure === null ? "--" : sliceDecimal(item.exposure)
                }}</span>
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          width="150px"
          align="center"
          prop="like"
          label="互动量"
        >
          <template #default="{ row }">
            <p class="pClass" v-if="articleInfoListFlag(row.articleInfoList)">
              {{
                row.articleInfoList[0].like === null
                  ? "--"
                  : sliceDecimal(row.articleInfoList[0].like)
              }}
            </p>
            <template
              v-if="
                row.isShowMoreArticle &&
                row.articleInfoList &&
                row.articleInfoList.length > 1
              "
            >
              <p
                class="pClass"
                :key="index"
                v-for="(item, index) in row.articleInfoList.slice(1)"
              >
                {{ item.like === null ? "--" : sliceDecimal(item.like) }}
              </p>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { getDetailsCoop } from "@/api/kolDetail";
import { formatData } from "./commonTool";

const store = useStore();
const rotate = ref("90deg");
const coopLoading = ref(true);
const coopList = ref([]);
const mKolid = computed(() => store.state.kolDetails.kolMkolId);
const articleInfoListFlag = (articleInfoList) => {
  return articleInfoList && articleInfoList.length;
};

getDetailsCoop(mKolid.value).then(({ code, data }) => {
  if (code === 0) {
    coopLoading.value = false;
    coopList.value = formatData(data);
  }
});

const iconClick = (row) => {
  row.isShowMoreArticle = !row.isShowMoreArticle;
  row.isRotate = !row.isRotate;
};
</script>

<style scoped lang="scss">
.coopWrap {
  margin-top: 10px;
  .logoWrap {
    display: flex;
    justify-content: space-around;
  }

  .iclass {
    position: relative;
    top: 1px;
    left: 2px;
    transition: transform 0.1s;
    cursor: pointer;
  }

  .rotateClass {
    transform: rotate(v-bind(rotate));
  }

  .brandNameClass {
    flex: 1;
    line-height: 58px;
  }

  .logo {
    width: 60px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-top: 9px;
      border: 1px solid #eee;
    }
  }

  .cardTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: $font-size-medium-x;
    border-bottom: 1px solid $color-title-underline;
    font-weight: 400;
    color: #000;
  }

  .pStyle {
    // line-height: 25px;
    height: 23px;
    line-height: 23px;
  }

  .aLinkClass {
    display: inline-block;
    height: 100%;
    text-decoration: none;
    font-size: $font-size-medium;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 10px;

    &:hover {
      color: #51a6f0;
    }
  }

  .headerClass {
    text-align: center;
  }
}
</style>
<style lang="scss">
.coopWrap {
  .el-card{
    border-radius: 10px;

  }
  .el-card__body {
    padding: 5px !important;
  }
}
</style>
