<template>
  <div class="manageBtn" @click="routerPush">
    <img :src="croSrc" />
    <span>管理人群</span>
    <img :src="arrowSrc" />
  </div>
</template>

<script>
export default {
  name: "manageBtn",
  data() {
    return {
      croSrc: require("./cro10_active.png"),
      arrowSrc: require("./arrow_active.png"),
    };
  },
  methods: {
    routerPush() {
      this.$router.push("/manage");
    },
  },
};
</script>

<style scoped lang="scss">
.manageBtn {
  height: 40px;
  line-height: 40px;
  background-color: #f9f9f9;
  cursor: pointer;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin: 0 10px 0 5px;
  }
}
</style>
