<template>
  <div class="flexContainer">
    <div class="noFlex">
      <slot name="one"></slot>
    </div>
    <div class="noFlex">
      <slot name="two"></slot>
    </div>
    <div class="flexItem">
      <slot name="three"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "col-flex-div",
  props: {
    flex1: {
      type: Boolean,
      default: false,
    },
    flex2: {
      type: Boolean,
      default: false,
    },
    flex3: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.flexContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .noFlex {
    flex: none;
  }

  .flexItem {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
