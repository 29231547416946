<template>
  <div class="kolDetailsWrap">
    <div class="title_block">
      基本指标
    </div>
    <div class="main_block">
      <div class="img_block">
        <div class="logo">
          <img :src="detailData.logoUrl" alt="" />
        </div>
        <div class="name">
          <p>{{ detailData.kolName }}</p>

        </div>
      </div>
      <div class="flex_direction">
        <!--                <img :src="detailData.logoUrl" alt="" />-->
        <div
            class="character-setting"
            v-if="dyOrXhs && isDetailGPTContent"
        >
          <div>人设更新时间</div>
          <span>{{ detailGPTContent.insertTime }}</span>
        </div>
      </div>
      <div
          class="character-setting-gtp"
          v-if="dyOrXhs && isDetailGPTContent"
      >
        <div class="content">
          <div class="left_title">
            达人人设
            <el-tooltip
                class="box-item"
                effect="dark"
                content="基于达人内容与用户评论由GPT生成"
                placement="top-start"
            >
              <svg-icon class-name="question" />
            </el-tooltip>
          </div>
          <div class="text">
            <!--            <el-tooltip-->
            <!--                class="box-item"-->
            <!--                effect="dark"-->
            <!--                :content="detailGPTContent.introduction"-->
            <!--                placement="top-start"-->
            <!--            >-->
            <span>
                        {{
                detailGPTContent.introduction ||
                "人设未制作，请联系客服"
              }}</span
            >
            <!--            </el-tooltip>-->
          </div>

        </div>
        <div class="tag">
          <div class="items">
            <div class="label">内容类型：</div>
            <div class="value">{{ detailGPTContent.contentType }}</div>
          </div>
          <div class="items">
            <div class="label">外貌特点：</div>
            <div class="value">{{ detailGPTContent.appearance }}</div>
          </div>
          <div class="items">
            <div class="label">兴趣特点：</div>
            <div class="value">{{ detailGPTContent.interest }}</div>
          </div>
          <div class="items">
            <div class="label">职业特点：</div>
            <div class="value">{{ detailGPTContent.job }}</div>
          </div>
          <div class="items">
            <div class="label">技能特点：</div>
            <div class="value">{{ detailGPTContent.skill }}</div>
          </div>
          <div class="items">
            <div class="label">性格特点：</div>
            <div class="value">{{ detailGPTContent.personality }}</div>
          </div>
          <div class="items">
            <div class="label">质疑槽点：</div>
            <div class="value">{{ detailGPTContent.challenge }}</div>
          </div>
        </div>
      </div>
      <div class="info_block">
        <p v-show="detailData.site === 'wechat'">
          微信号：
        </p>
        <p class="leftTitle" v-show="detailData.site === 'wechat'">
          {{ detailData.kolNo }}
        </p>
        <p>介绍：</p>
        <p class="leftTitle" v-if="detailData.detail">
          {{ detailData.detail }}
        </p>
        <p v-show="detailData.site === 'wechat' && detailData.subject">认证：</p>
        <p class="leftTitle" v-show="detailData.site === 'wechat' && detailData.subject ">
          {{ detailData.subject }}
        </p>
      </div>
      <div class="tier_block" v-if="detailData.site !== 'wechat'">
        Tier<p class="tireClass" v-if="detailData.tier !== 0">{{ detailData.tierStr }}</p>
        <p class="tireClass" v-else>KOC</p>
      </div>
      <div class="num_block">
        <div class="num_div">
          <p class="dataTitle">{{ uvStr() }}</p>
          <p style=" font-weight: 900">
            {{ uvNum }}
          </p>
          <p class="relative">
            较上月
            <template v-if="flagList.allUvGrowFlag !== 0"></template>
            <svg-icon
                :class-name="flagList.allUvGrowFlag ? 'upIcon' : 'downIcon'"
                :class="[
                      flagList.allUvGrowFlag ? 'up' : 'down',
                      'iconClass',
                    ]"
            ></svg-icon>
            <span class="ml-5">{{ flagList.allUvGrow }}</span>
          </p>
        </div>
        <div class="num_div" v-if="detailData.site !== 'wechat'">
          <p class="dataTitle">粉丝数</p>
          <p style="font-weight: 900">
            {{
              detailData.fans === ""
                  ? "- -"
                  : sliceDecimal(detailData.fans)
            }}
          </p>
          <p class="relative">
            较上月
            <template v-if="flagList.fansGrowFlag !== 0">
              <svg-icon
                  :class-name="
                        flagList.fansGrowFlag ? 'upIcon' : 'downIcon'
                      "
                  :class="[
                        flagList.fansGrowFlag ? 'up' : 'down',
                        'iconClass',
                      ]"
              ></svg-icon>
            </template>
            <span class="ml-5">{{ flagList.fansGrow }}</span>
          </p>
        </div>
        <div  class="num_div" >
          <p class="dataTitle">{{ postStr() }}</p>
          <p style=" font-weight: 900">
            {{ detailData.post }}
          </p>
          <p>
            较上月
            <template v-if="flagList.postGrowFlag !== 0"></template>
            <svg-icon
                :class-name="flagList.postGrowFlag ? 'upIcon' : 'downIcon'"
                :class="[
                      flagList.postGrowFlag ? 'up' : 'down',
                      'iconClass',
                    ]"
            ></svg-icon>
            <span class="ml-5">{{ flagList.postGrow }}</span>
          </p>
        </div>
      </div>
      <div class="rightDiv" v-if="detailData.site === 'wechat'">
        <p>微信扫一扫关注</p>
        <qr-code :value="detailData.qrCode" />
      </div>
      <div class="rightDiv" v-else>
        <p>点击/扫码访问主页</p>
        <a
            :href="detailData.qrCode"
            target="_blank"
            class="text-center pt-10"
        >
          <qr-code :value="detailData.qrCode" :options="{ width: 110 }" />
        </a>
      </div>
    </div>
<!--    <el-card v-loading="baseDataLoading">-->
<!--      <div class="topDiv">-->
<!--        <p class="title">-->
<!--          <span>基本指标 -&nbsp;</span>-->
<!--          <span style="color: #51a6f0">-->
<!--            {{ detailData.kolName }}-->
<!--          </span>-->
<!--        </p>-->
<!--        <div class="flex p20">-->
<!--          <div class="left">-->
<!--            <div :class="['baseData']">-->
<!--              <div class="flex_direction">-->
<!--                <img :src="detailData.logoUrl" alt="" />-->
<!--                <div-->
<!--                  class="character-setting"-->
<!--                  v-if="dyOrXhs && isDetailGPTContent"-->
<!--                >-->
<!--                  <div>人设更新时间</div>-->
<!--                  <span>{{ detailGPTContent.insertTime }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div-->
<!--                class="character-setting-gtp"-->
<!--                v-if="dyOrXhs && isDetailGPTContent"-->
<!--              >-->
<!--                <div class="content">-->
<!--                  <div class="title">达人人设</div>-->
<!--                  <div class="text">-->
<!--                    <el-tooltip-->
<!--                      class="box-item"-->
<!--                      effect="dark"-->
<!--                      content="基于达人内容与用户评论由GPT生成"-->
<!--                      placement="top-start"-->
<!--                    >-->
<!--                      <svg-icon class-name="question" />-->
<!--                    </el-tooltip>-->
<!--                    <el-tooltip-->
<!--                      class="box-item"-->
<!--                      effect="dark"-->
<!--                      :content="detailGPTContent.introduction"-->
<!--                      placement="top-start"-->
<!--                    >-->
<!--                      <span>-->
<!--                        {{-->
<!--                          detailGPTContent.introduction ||-->
<!--                          "人设未制作，请联系客服"-->
<!--                        }}</span-->
<!--                      >-->
<!--                    </el-tooltip>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="tag">-->
<!--                  <div class="items">-->
<!--                    <div class="label">内容类型：</div>-->
<!--                    <div class="value">{{ detailGPTContent.contentType }}</div>-->
<!--                  </div>-->
<!--                  <div class="items">-->
<!--                    <div class="label">外貌特点：</div>-->
<!--                    <div class="value">{{ detailGPTContent.appearance }}</div>-->
<!--                  </div>-->
<!--                  <div class="items">-->
<!--                    <div class="label">兴趣特点：</div>-->
<!--                    <div class="value">{{ detailGPTContent.interest }}</div>-->
<!--                  </div>-->
<!--                  <div class="items">-->
<!--                    <div class="label">职业特点：</div>-->
<!--                    <div class="value">{{ detailGPTContent.job }}</div>-->
<!--                  </div>-->
<!--                  <div class="items">-->
<!--                    <div class="label">技能特点：</div>-->
<!--                    <div class="value">{{ detailGPTContent.skill }}</div>-->
<!--                  </div>-->
<!--                  <div class="items">-->
<!--                    <div class="label">性格特点：</div>-->
<!--                    <div class="value">{{ detailGPTContent.personality }}</div>-->
<!--                  </div>-->
<!--                  <div class="items">-->
<!--                    <div class="label">质疑槽点：</div>-->
<!--                    <div class="value">{{ detailGPTContent.challenge }}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div-->
<!--                :class="[-->
<!--                  'baseDataStyle',-->
<!--                  'flex-1',-->
<!--                  'ml-20',-->
<!--                  `${dyOrXhs && isDetailGPTContent ? 'flex-1' : 'flex-7'}`,-->
<!--                ]"-->
<!--              >-->
<!--                <p class="leftTitle" v-show="detailData.site === 'wechat'">-->
<!--                  微信号：{{ detailData.kolNo }}-->
<!--                </p>-->
<!--                <p class="leftTitle">-->
<!--                  标签：-->
<!--                  <el-tag size="mini" class="mr-5">-->
<!--                    {{ detailData.kolCategory }}-->
<!--                  </el-tag>-->
<!--                </p>-->
<!--                <p class="tireClass" v-if="detailData.tier !== 0">-->
<!--                  <svg-icon-->
<!--                    @click="showTierDialog"-->
<!--                    class-name="question"-->
<!--                    class="pointer"-->
<!--                  />-->
<!--                  Tier: &nbsp;&nbsp;Tier {{ detailData.tier }}-->
<!--                </p>-->
<!--                <p v-else class="font-12">Tier: &nbsp;&nbsp;KOC</p>-->
<!--                <p class="leftTitle" :title="detailData.detail">-->
<!--                  介绍：{{ detailData.detail }}-->
<!--                </p>-->
<!--                <p class="leftTitle" v-show="detailData.site === 'wechat'">-->
<!--                  认证：{{ detailData.subject }}-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="dataShow">-->
<!--              <div>-->
<!--                <p class="dataTitle">{{ uvStr() }}</p>-->
<!--                <p style="color: #51a6f0; font-weight: 900">-->
<!--                  {{ uvNum }}-->
<!--                </p>-->
<!--                <p class="relative">-->
<!--                  较上月-->
<!--                  <svg-icon-->
<!--                    :class-name="flagList.allUvGrowFlag ? 'upIcon' : 'downIcon'"-->
<!--                    :class="[-->
<!--                      flagList.allUvGrowFlag ? 'up' : 'down',-->
<!--                      'iconClass',-->
<!--                    ]"-->
<!--                  ></svg-icon>-->
<!--                  <span class="ml-5">{{-->
<!--                    flagList.allUvGrow === "NaN%" ||-->
<!--                    flagList.allUvGrow === "Infinity%"-->
<!--                      ? "&#45;&#45;"-->
<!--                      : flagList.allUvGrow-->
<!--                  }}</span>-->
<!--                </p>-->
<!--              </div>-->
<!--              <div v-if="detailData.site !== 'wechat'">-->
<!--                <p class="dataTitle">粉丝数</p>-->
<!--                <p style="color: #51a6f0; font-weight: 900">-->
<!--                  {{-->
<!--                    detailData.fans === ""-->
<!--                      ? "- -"-->
<!--                      : sliceDecimal(detailData.fans)-->
<!--                  }}-->
<!--                </p>-->
<!--                <p class="relative">-->
<!--                  较上月-->
<!--                  <svg-icon-->
<!--                    :class-name="flagList.fansGrowFlag ? 'upIcon' : 'downIcon'"-->
<!--                    :class="[-->
<!--                      flagList.fansGrowFlag ? 'up' : 'down',-->
<!--                      'iconClass',-->
<!--                    ]"-->
<!--                  ></svg-icon>-->
<!--                  <span class="ml-5">{{-->
<!--                    flagList.fansGrow === "NaN%" ||-->
<!--                    flagList.fansGrow === "Infinity%"-->
<!--                      ? "&#45;&#45;"-->
<!--                      : flagList.fansGrow-->
<!--                  }}</span>-->
<!--                </p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <p class="dataTitle">{{ postStr() }}</p>-->
<!--                <p style="color: #51a6f0; font-weight: 900">-->
<!--                  {{ detailData.post }}-->
<!--                </p>-->
<!--                <p>-->
<!--                  较上月-->
<!--                  <svg-icon-->
<!--                    :class-name="flagList.postGrowFlag ? 'upIcon' : 'downIcon'"-->
<!--                    :class="[-->
<!--                      flagList.postGrowFlag ? 'up' : 'down',-->
<!--                      'iconClass',-->
<!--                    ]"-->
<!--                  ></svg-icon>-->
<!--                  <span class="ml-5">{{-->
<!--                    flagList.postGrow === "NaN%" ||-->
<!--                    flagList.postGrow === "Infinity%"-->
<!--                      ? "&#45;&#45;"-->
<!--                      : flagList.postGrow-->
<!--                  }}</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="rightDiv" v-if="detailData.site === 'wechat'">-->
<!--            <p>微信扫一扫关注</p>-->
<!--            <qr-code :value="detailData.qrCode" />-->
<!--          </div>-->
<!--          <div class="rightDiv" v-else>-->
<!--            <p>点击/扫码访问主页</p>-->
<!--            <a-->
<!--              :href="detailData.qrCode"-->
<!--              target="_blank"-->
<!--              class="text-center pt-10"-->
<!--            >-->
<!--              <qr-code :value="detailData.qrCode" :options="{ width: 110 }" />-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </el-card>-->
    <el-dialog v-model="showTier" width="40%" append-to-body>
      <img class="imgClass" :src="require('./tier.png')" alt="" />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { detailGPT } from "@/api/rankingList";

const props = defineProps({
  kolMkolId: {
    type: String,
    required: true,
  },
  platform: {
    type: String,
    require: true,
  },
});
const store = useStore();
let uvNum = ref("");
const showTier = ref(false);
const baseDataLoading = ref(true);
const detailGPTContent = ref({});
const isDetailGPTContent = ref(false);

const kolParams = reactive({
  mKolid: props.kolMkolId,
  platform: props.platform,
});

const flagList = reactive({
  allUvGrow: "",
  allUvGrowFlag: false,
  fansGrow: "",
  fansGrowFlag: false,
  postGrow: "",
  postGrowFlag: false,
});
onMounted(() => {
  store.dispatch("kolDetails/getKolDetails", kolParams).then(() => {
    baseDataLoading.value = false;
  });
  store.commit("SET_DETAILSTORANKLIST", props.platform);
  getGptDetail();
});

const detailData = computed(() => store.state.kolDetails.detailData);
const dyOrXhs = computed(() => {
  const site = store.state.kolDetails.detailData.site;
  return site === "douyin" || site === "xiaohongshu"
});

const getGptDetail = async () => {
  const { data } = await detailGPT({ mKolid: props.kolMkolId });
  detailGPTContent.value = data;
  isDetailGPTContent.value = !!Object.values(data).length;
};

watch(
  () => store.state.kolDetails.detailData,
  () => {
    uvNum.value = filUvNum();
    setFlagVal();
  },
  {
    deep: true, // 深度监听
  }
);
const setFlagVal = () => {
  flagList.allUvGrowFlag = setNum("allUv", "lastMonthAllUv")[1];
  flagList.allUvGrow = setNum("allUv", "lastMonthAllUv")[0];
  flagList.postGrowFlag = setNum("post", "lastMonthPost")[1];
  flagList.postGrow = setNum("post", "lastMonthPost")[0];
  flagList.fansGrowFlag = setNum("fans", "lastMonthFans")[1];
  flagList.fansGrow = setNum("fans", "lastMonthFans")[0];
};
const showTierDialog = () => {
  showTier.value = true;
};
const setNum = (filed, lastVal) => {
  console.log(
    Math.abs(
      (1 - detailData.value[lastVal] / detailData.value[filed]) * 100
    ).toFixed(2) + "%"
  );
  return [
    Math.abs(
      (1 - detailData.value[filed] / detailData.value[lastVal]) * 100
    ).toFixed(2) + "%",
    detailData.value[filed] > detailData.value[lastVal],
  ];
};
const uvStr = () => {
  switch (detailData.value.site) {
    case "wechat":
      return "月阅读UV";
    case "weibo":
      return "月转评赞UV";
    case "douyin":
      return "月浏览UV";
    case "xiaohongshu":
      return "月阅读UV";
    case "bilibili":
      return "月浏览UV";
    case "kuaishou":
      return "月浏览UV";
    default:
      return "";
  }
};
const postStr = () => {
  switch (detailData.value.site) {
    case "wechat":
      return "月文章数";
    case "weibo":
      return "月推文数";
    case "douyin":
      return "月视频数";
    case "xiaohongshu":
      return "月笔记数";
    case "kuaishou":
      return "月视频数";
    case "bilibili":
      return "月视频数";
  }
};
const filUvNum = () => {
  let tempStr = store.state.kolDetails.detailData.allUv
    ? store.state.kolDetails.detailData.allUv.toString()
    : "";
  if (!tempStr.length) {
    return "- -";
  }
  if (tempStr.length > 5) {
    let str1 = tempStr.slice(0, tempStr.length - 4);
    let str2 = tempStr.slice(-4, -3);
    return str1 + "." + str2 + "w";
  } else {
    return store.state.kolDetails.detailData.allUv;
  }
};
</script>
<style scoped lang="scss">
.imgClass {
  width: 100%;
  height: 100%;
}

.tireClass {
  font-size: 12px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.topDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;

  .left {
    flex: 1;
    display: flex;
    .baseData {
      display: flex;
      border-right: 1px solid $color-title-underline;
      .baseDataStyle {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right: 10px;
      }
      img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 1px solid #ddd;
        margin: auto;
      }
    }
    .dataShow {
      flex: 3;
      display: flex;
      div {
        padding-top: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex: 1;
        p {
          margin-bottom: 15px;
        }
      }
      .dataTitle {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        font-weight: 900;
      }
    }

    .leftTitle {
      margin-bottom: 10px;
      font-size: $font-size-small;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-width: 200px;
    }

    .leftUl {
      display: flex;
      line-height: 30px;
      margin-bottom: 15px;

      li {
        flex: 1;
        padding-left: 10px;
        border-left: 1px solid #f1f1f1;

        p {
          font-size: $font-size-small;
          font-weight: bold;
        }
      }
    }
  }

  .rightDiv {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    p {
      text-align: center;
    }

    img {
      width: 110px;
      height: 110px;
    }
  }
}

.midDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.line {
  width: 100%;
  flex: none;
  height: 300px;
}

.title {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-size: $font-size-medium-x;
  border-bottom: 1px solid $color-title-underline;
  display: flex;
  align-items: center;
  font-weight: 900;
  color: #000;
}
.iconClass {
  width: 20px !important;
  height: 20px !important;
  vertical-align: bottom;
}
.up {
  fill: #67c23a;
}

.down {
  fill: #f56c6c;
}
.character-setting {
  text-align: center;
  font-size: 12px;
}

.character-setting-gtp {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .content {
    padding-bottom: 20px;
    margin-right: 16px;
    width: 200px;

    .title {
      height: 26px;
      padding-left: 20px;
      border-bottom: 0;
    }
    .text {
      padding-left: 20px;
      position: relative;
      display: flex;
      align-items: center;
      height: 150px;
      overflow: hidden; //超出的文本隐藏
      display: -webkit-box;
      -webkit-line-clamp: 7; // 超出多少行
      -webkit-box-orient: vertical; 
    }
    .icon {
      position: absolute;
      left: 0;
      top: 4px;
    }
  }

  .tag {
    flex-grow: 1;

    .items {
      display: flex;
      justify-content: space-between;

      .label {
        width: 76px;
        color: #666;
      }
      .value {
        color: #666;
        flex-grow: 1;
        max-width: 180px;
      }
    }
  }
}
</style>

<style lang="scss">
.kolDetailsWrap {
  .el-card__body {
    padding: 5px !important;
  }
}
</style>
<style scoped lang="scss">
.kolDetailsWrap {
  border-radius:20px;
  min-height:100%;
  background: #fff;
  .title_block{
    height:67px;
    line-height: 67px;
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 500;
    color:#222;
    padding:0 20px;
    border-bottom:1px solid #ededed;

  }
  .main_block{
    padding:0 20px;
    .img_block{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0;
      gap:5px;
      .logo{
        width:20%;
        aspect-ratio: 1/1;
        img{
          width: 100%;
          height:100%;
        }
      }
    }
    .info_block{
      padding:20px 0;
      border-bottom:1px solid #ededed;
      display: flex;
      flex-direction: column;
      gap:5px;
    }
    .tier_block{
      padding:20px 0;
      border-bottom:1px solid #ededed;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap:20px;
      .tireClass{
        padding: 2px 10px;
        border-radius: 6xp;
        background: rgba(217, 217, 217, 0.32);

      }
    }
    .num_block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:20px 0;
      border-bottom:1px solid #ededed;
      .num_div{
        display: flex;
        flex-direction: column;
        gap:20px;
      }
    }
    .rightDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      p {
        text-align: center;
      }

      img {
        width: 110px;
        height: 110px;
      }
    }
  }
}
</style>