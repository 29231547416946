<template>
  <ul>
    <li :style="liLineHeight" v-for="(item, index) in data" :key="index">
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "ul-list-com",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default() {
        return ["kol1", "kol2", "kol3", "kol4", "kol5"];
      },
    },
    lineHeight: {
      type: String,
      default: "35px",
    },
  },
  computed: {
    liLineHeight() {
      return {
        lineHeight: this.lineHeight,
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
