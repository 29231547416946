<template>
  <div class="crosswiseBarDiv" ref="doubleYChart"></div>
</template>

<script>
/*
 * 传title
 * xAxisData
 * seriesData
 *
 * */

import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { nextTick } from "vue";

echarts.use([
  BarChart,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  CanvasRenderer,
]);
//对Proxy对象进行拆箱。
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);

export default {
  name: "double-y-bar",
  data() {
    return {
      myChart: "",
      spliteNum: 0,
      perInterval: 10,
      TGIInterval: 0.25,
    };
  },
  props: {
    seriesData: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs.doubleYChart);
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawRowBar() {
      unwarp(this.myChart).setOption({
        grid: {
          show: false,
          top: 40,
          right: 40,
          bottom: 31,
          left: 40,
        },
        title: {
          text: this.title,
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
          },
          left: 30,
          top: 10,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: (params) => {
            let str = `<p>${params[0].name}</p>`;
            str += `<p>${params[0].marker}占比：${params[0].value.toFixed(
              2
            )}%</p>`;
            str += `<p>${params[1].marker}TGI：${params[1].value.toFixed(
              2
            )}</p>`;
            return str;
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
          },
          axisLine: {
            lineStyle: {
              color: "#ccc",
            },
          },
          axisTick: {
            show: false,
            alignWithLabel: true,
          },
          data: this.seriesData.xaxias,
        },
        yAxis: [
          {
            type: "value",
            name: "占比",
            show: false,
            axisLabel: {
              color: "#51a6f0",
              formatter: "{value}%",
            },
            axisLine: {
              lineStyle: {
                color: "#51a6f0",
              },
            },
            nameTextStyle: {
              color: "#51a6f0",
            },
            max: (value) => {
              this.spliteNum = Math.ceil(value.max / 10);
              if (this.spliteNum > 5) {
                this.perInterval = 20;
                if (this.spliteNum % 2 === 0) {
                  this.spliteNum = this.spliteNum / 2;
                  return Math.ceil(value.max / 10) * 10;
                } else {
                  this.spliteNum = (this.spliteNum + 1) / 2;
                  return (Math.ceil(value.max / 10) + 1) * 10;
                }
              } else {
                this.perInterval = 10;
                return Math.ceil(value.max / 10) * 10;
              }
            },
            interval: this.perInterval,
          },
          {
            type: "value",
            name: "TGI",
            show: false,
            axisLine: {
              lineStyle: {
                color: "#A8C9E9",
              },
            },
            axisLabel: {
              color: "#A8C9E9",
            },
            nameTextStyle: {
              color: "#A8C9E9",
            },
            interval: this.TGIInterval,
          },
        ],
        series: [
          {
            type: "bar",
            name: "占比",
            label: {
              show: true,
              position: "top",
              formatter: (params) => {
                let text = params.data.toFixed(1);
                if (text >= 10) {
                  text = Math.ceil(text);
                } else if (text < 0.08) {
                  text = 0;
                }
                return text;
              },
            },
            data: this.seriesData.series1,
          },
          {
            type: "bar",
            name: "TGI",
            label: {
              show: true,
              position: "top",
              formatter: (params) => {
                let text = params.data.toFixed(1);
                return text;
              },
            },
            data: this.seriesData.series2,
            yAxisIndex: 1,
          },
        ],
        color: ["#51a6f0", "#A8C9E9"],
        textStyle: {
          fontFamily: "Microsoft YaHei",
          color: "#111",
        },
      });
    },
    resize() {
      this.myChart.resize();
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 300);
      });
    },
  },
  watch: {
    seriesData() {
      this.drawRowBar();
    },
    perInterval() {
      this.drawRowBar();
    },
    TGIInterval() {
      this.drawRowBar();
    },
  },
};
</script>

<style scoped lang="scss">
.crosswiseBarDiv {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
