<template>
  <div class="lengthwaysBarDiv" ref="chart"></div>
</template>

<script>
/*
 * 传seriesData，默认为空
 * 传柱图颜色，默认为'#DFE5EF'
 * percentage格式 默认正常 传‘%’
 * 是计算高度还是自适应高度
 * */

import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([BarChart, GridComponent, CanvasRenderer]);

import { nextTick } from "vue";

export default {
  name: "lengthways-bar",
  data() {
    return {};
  },
  props: {
    seriesData: {
      type: Array, // return arr  最多20个
      default: () => [],
    },
    color: {
      type: String,
      default: "#5696BE",
    },
    percentage: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 0,
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs.chart);
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawColBar() {
      this.myChart.setOption({
        grid: {
          show: false,
          top: -2,
          bottom: 0,
          left: 0,
          right: "30%",
        },
        xAxis: {
          type: "value",
          show: false,
          max: this.max,
        },
        yAxis: {
          type: "category",
          show: false,
          inverse: true,
        },
        series: {
          type: "bar",
          data: this.seriesData,
          label: {
            show: true,
            position: "right",
            formatter: (params) => {
              // 判断显示name还是value
              if (this.showName) {
                return params.data.name + this.percentage;
              } else {
                return this.sliceDecimal(params.data) + this.percentage;
              }
            },
          },
          itemStyle: {
            color: this.color,
          },
          barWidth: 20,
          cursor: "default",
        },
        textStyle: {
          fontFamily: "Microsoft YaHei",
          color: "#111",
        },
      });
    },
    resize() {
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 500);
      });
    },
  },
  watch: {
    seriesData() {
      this.drawColBar();
      this.myChart.resize();
    },
  },
};
</script>

<style scoped lang="scss">
.lengthwaysBarDiv {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
