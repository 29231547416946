<template>
  <ul class="crowed_item_content">
    <li
      v-for="(item, index) in crowdList"
      :key="index"
      @click="handleClick(item)"
    >
      <img :src="item.key === seg ? chosenSrc : normalSrc" />
      <span>{{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { setCrowd } from "@/api/collection";

export default {
  name: "crowed-item",
  data() {
    return {
      normalSrc: require("./point_normal.png"),
      chosenSrc: require("./point_chosen.png"),
    };
  },
  computed: {
    ...mapGetters(["crowdList", "categorySeg", "colCategorySeg"]),
    seg() {
      return this.$route.name === "RankingListV2"
        ? this.categorySeg
        : this.colCategorySeg;
    },
  },
  methods: {
    ...mapMutations({
      setTargetCrowd: "SET_TARGETCROWD",
      setColTargetCrowd: "SET_COLTARGETCROWD",
    }),
    async handleClick(data) {
      if (this.$route.name === "RankingListV2") {
        this.setTargetCrowd(data);
      } else {
        setCrowd({
          collectionId: this.atob(this.$route.query.collectionId) - 0,
          crowd: data.key,
        });
        this.setColTargetCrowd(data);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.crowed_item_content {
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;

  li {
    min-width: 180px;
    height: 27px;
    line-height: 27px;
    padding: 0 19px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      color: #ffffff;
      background-color: #51a6f0;
    }

    img {
      margin-right: 8px;
    }
  }
}
</style>
