<template>
  <el-table
    :data="tableData.tableInfo"
    ref="table"
    stripe
    size="small"
    v-loading="loading"
  >
    <el-table-column
      :resizable="resizable"
      v-for="(item, index) in tableData.colData"
      :key="index"
      :prop="item.prop"
      :label="item.label"
      :fixed="index === 0"
    />
  </el-table>
</template>

<script>
/*
 * 传过来的tableData里面有tableinfo和coldata
 * loading动画，默认开启
 * */

export default {
  name: "table-com",
  data() {
    return {
      resizable: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
