<template>
  <div style="display: flex">
    <col-flex-div v-loading="loading" class="containerDiv" v-if="site === 'weibo'">
      <template #one>
        <four-item-div>
          <template #one>
            <div class="title" style="padding-left: 10px">KOL名称</div>
          </template>
          <template #two>
            <div class="title">累积触达人数</div>
          </template>
          <template #three>
            <div class="title">重复人数</div>
          </template>
          <template #four>
            <div class="title">重复人数比率</div>
          </template>
        </four-item-div>
      </template>
      <template #two>
        <four-item-div>
          <template #one>
            <div class="subtitle"></div>
          </template>
          <template #two>
            <div class="subtitle">累计递增的转评赞访客数（UV）</div>
          </template>
          <template #three>
            <div class="subtitle">KOL重复人数（UV）</div>
          </template>
          <template #four>
            <div class="subtitle">%，KOL重复比率</div>
          </template>
        </four-item-div>
      </template>
      <template #three>
        <four-item-div>
          <template #one>
            <ul-list-com :data="ulListData" style="margin-left: 10px" />
          </template>
          <template #two>
            <step-bar :supportData="supportData2" :seriesData="seriesData2" :showName="showName" />
          </template>
          <template #three>
            <lengthways-bar :seriesData="dupUvs" :max="dupUvsMax" :showName="showName" />
          </template>
          <template #four>
            <lengthways-bar :seriesData="dupPers" :percentage="'%'" :color="bar2Color" :max="100" :showName="showName" />
          </template>
        </four-item-div>
      </template>
    </col-flex-div>
    <col-flex-div v-loading="loading" class="containerDiv" v-else>
      <template #one>
        <five-item-div>
          <template #one>
            <div class="title" style="padding-left: 10px">KOL名称</div>
          </template>
          <template #two>
            <div class="title">
              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div class="toolTipText">
                    <p>在前面达人已触达的人群基础上，</p>
                    <p>带来的额外访问数</p>
                  </div>
                </template>
                <div class="title-text flex_start">
                  <span>KOL近四周递增独立访客数（UV）</span>
                  <el-icon>
                    <Warning />
                  </el-icon>
                </div>
              </el-tooltip>
            </div>
          </template>
          <template #three>
            <div class="title">累积渗透率</div>
          </template>
          <template #four>
            <div class="title">
              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div class="toolTipText">
                    <p>该达人触达到的人群，与前面的达人</p>
                    <p>已触达到的人群，之间重复的访客数</p>
                  </div>
                </template>
                <div class="title-text flex_start">
                  <span>重复访客数（UV）</span>
                  <el-icon>
                    <Warning />
                  </el-icon>
                </div>
              </el-tooltip>
            </div>
          </template>
          <template #five>
            <div class="title">
              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div class="toolTipText">
                    <p>重复访客数/（重复访客数+递增独立访客数）</p>
                  </div>
                </template>
                <div class="title-text flex_start">
                  <span>重复人群比率（%）</span>
                  <el-icon>
                    <Warning />
                  </el-icon>
                </div>
              </el-tooltip>
            </div>
          </template>
        </five-item-div>
      </template>
      <template #two>
        <!-- <five-item-div>
          <template #one>
            <div class="subtitle"></div>
          </template>
          <template #two>
            <div class="subtitle">
              <p>KOL近四周递增独立访客数（UV）</p>
            </div>
          </template>
          <template #three>
            <div class="subtitle">%，累计递增的独立访客渗透率</div>
          </template>
          <template #four>
            <div class="subtitle">
              <p>重复访客数（UV）</p>
            </div>
          </template>
          <template #five>
            <div class="subtitle">
              <p>重复人群比率（%）</p>
            </div>
          </template>
        </five-item-div> -->
      </template>
      <template #three>
        <five-item-div>
          <template #one>
            <ul-list-com ref="listDom" :data="ulListData" style="margin-left: 10px" />
          </template>
          <template #two>
            <step-bar :supportData="supportData2" :seriesData="seriesData2" :showName="showName" />
          </template>
          <template #three>
            <step-bar :supportData="supportData1" :seriesData="seriesData1" :percentage="'%'" :color="bar2Color"
              :showName="showName" />
          </template>
          <template #four>
            <lengthways-bar :seriesData="dupUvs" :max="dupUvsMax" :showName="showName" />
          </template>
          <template #five>
            <lengthways-bar :seriesData="dupPers" :percentage="'%'" :color="bar2Color" :max="100" :showName="showName" />
          </template>
        </five-item-div>
      </template>
    </col-flex-div>
  </div>
</template>

<script>
import { Warning } from "@element-plus/icons";
import colFlexDiv from "@/components/3colFlexDiv";
import fourItemDiv from "@/components/4ItemDiv";
import stepBar from "@/components/echarts/stepBar";
import ulListCom from "@/components/ulListCom";
import fiveItemDiv from "@/components/5ItemDiv";
import lengthwaysBar from "@/components/echarts/lengthwaysBar";

export default {
  name: "touch-analysis",
  data() {
    return {
      ulListData: [],
      supportData1: [],
      seriesData1: [],
      supportData2: [],
      seriesData2: [],
      dupUvs: [],
      dupPers: [],
      bar2Color: "rgb(101, 166, 234)",
      dupUvsMax: null,
      showName: false,
    };
  },
  props: {
    // 传递过来的数据
    touchAnalysisData: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: true,
    },
    // 针对微博平台列数不同
    site: {
      type: String,
      default: "wechat",
    },
  },
  methods: {
    sum(data) {
      let sum = 0;
      for (let item of data) {
        sum += item;
      }
      return sum;
    },
    formatData(data) {
      let arr;
      arr = data.map((item) => {
        return { name: "--", value: item };
      });
      return arr;
    },
  },
  watch: {
    touchAnalysisData() {
      this.showName = false;
      this.ulListData = this.touchAnalysisData.kolNams;
      this.supportData1 = this.touchAnalysisData.pre.serier2;
      this.seriesData1 = this.touchAnalysisData.pre.serier1;
      this.supportData2 = this.touchAnalysisData.uv.serier2;
      this.seriesData2 = this.touchAnalysisData.uv.serier1;
      this.dupPers = this.touchAnalysisData.dupPers;
      this.dupUvs = this.touchAnalysisData.dupUvs;
      /*
       * dupUvs dupPers的最大值要取uv.serier1 pre.serier1的和
       * 新增判断dupPers的总和是否大于100
       * 大于100要将数据处理为 {name：'--'，value: item}
       * */
      // const dupPersMax = this.sum(this.seriesData1);
      this.dupUvsMax = this.sum(this.seriesData2);
      // if (dupPersMax > 100) {
      //   this.showName = true;
      //   this.seriesData2 = this.formatData(this.seriesData2);
      //   this.seriesData1 = this.formatData(this.seriesData1);
      //   this.dupUvs = this.formatData(this.dupUvs);
      //   this.dupPers = this.formatData(this.dupPers);
      // }
    },
  },
  components: {
    colFlexDiv,
    fourItemDiv,
    stepBar,
    ulListCom,
    fiveItemDiv,
    lengthwaysBar,
    Warning
  },
};
</script>

<style scoped lang="scss">
.toolTipText {
  color: #fff;
}

.title-text {
  width: 220px;
}
</style>
