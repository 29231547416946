<template>
  <div class="articleTrend">
    <div class="trend" v-loading="trendLoading">
      <div class="midDiv line">
        <p class="trendTitle">
          <span>单篇文章流量变化</span>
          <legends
            :legendColor="articleColor"
            :legendData="trendData.legend.data"
          />
        </p>
        <line-bar :series="trendData.series" :xAxis="trendData.xAxis" />
      </div>
    </div>
    <div class="articleTable" v-loading="hotArticlesLoading">
      <div class="midDiv">
        <p class="trendTitle">热门发帖</p>
        <article-list :data="hotArticles" :kolName="kolName" style="flex: 1" />
      </div>
    </div>
  </div>
</template>

<script setup>
import legends from "@/views/KolDetail/legends";
import lineBar from "@/components/echarts/lineBar";
import articleList from "./articleList";
import { reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
const params = reactive({
  // 人群写死 32_1
  seg: "32_1",
  mKolid: store.state.kolDetails.kolMkolId,
  platform: store.state.kolDetails.site,
});
const hotListParams = reactive({
  mKolid: store.state.kolDetails.kolMkolId,
  platform: store.state.kolDetails.site,
});
onMounted(() => {
  store.dispatch("kolDetails/getDetailsTrend", params).then(() => {
    store.commit("kolDetails/setTrendLoading", false);
  });
  store.dispatch("kolDetails/getArticleList", hotListParams).then(() => {
    store.commit("kolDetails/setHotArticlesLoading", false);
  });
});
const trendData = computed(() => store.state.kolDetails.trendData);
const articleColor = computed(() => store.state.kolDetails.articleColor);
const hotArticles = computed(() => store.state.kolDetails.hotArticles);
const kolName = computed(() => store.state.kolDetails.kolName);
const trendLoading = computed(() => store.state.kolDetails.trendLoading);
const hotArticlesLoading = computed(
  () => store.state.kolDetails.hotArticlesLoading
);
const setTrendData = (articleHistory) => {
  articleHistory.series.forEach((item) => {
    for (let i = 0; i < item.data.length; i++) {
      if (item.data[i] === null) {
        item.data[i] = 0;
      }
    }
  });
};
watch(
  () => store.state.kolDetails.trendData,
  (newVal) => {
    setTrendData(newVal);
  },
  {
    deep: true,
  }
);
</script>

<style scoped lang="scss">
.articleTrend {
  display: flex;
  .articleTable,
  .trend {
    flex: 1;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
    border-radius: 10px;
    background: #fff;
    .trendTitle {
      font-weight: normal;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-size: $font-size-medium-x;
      border-bottom: 1px solid $color-title-underline;
      display: flex;
      align-items: center;
    }
  }
  .articleTable {
    margin-left: 10px;
    background-color: #fff;
  }
  .midDiv {
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  .line {
    width: 100%;
    height: 371px;
  }
}
</style>
<style lang="scss">
.articleTrend {
  .el-card__body {
    padding: 5px !important;
  }
}
</style>
