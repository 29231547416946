<template>
  <div class="newSelectCom">
    <el-input
      v-model="input"
      placeholder="名称或关键字，空格分隔"
      size="mini"
      suffix-icon="el-icon-search"
      class="search"
      @change="handleInput"
      @keyup.enter="handleSearch"
      clearable
      @clear="handleSearch"
    >
    </el-input>
    <span class="btn handleTagBtn" @click="handleTagShow">
      {{ modelValue ? "收起" : "筛选" }}&nbsp;
      <i v-show="modelValue" class="el-icon-arrow-up"></i>
      <i v-show="!modelValue" class="el-icon-arrow-down"></i>
    </span>
    <span
      class="btn resetBtn"
      v-show="tagshowNumber"
      @mouseover="resetHover = true"
      @mouseout="resetHover = false"
    >
      （已选
      <span class="text">
        {{ tagshowNumber }}
      </span>
      个条件）
      <i class="el-icon-close" style="cursor: pointer" @click="cleanTags"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "new-select-com",
  data() {
    return {
      input: "",
      resetHover: false,
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    tagshowNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    handleTagShow() {
      this.$emit("update:modelValue", !this.modelValue);
    },
    handleSearch() {
      this.$emit("handleSearch");
    },
    handleInput() {
      this.$emit("changeInput", this.input);
    },
    cleanTags() {
      // 清空就是全部置为all
      this.$emit("resetData", true);
    },
  },
};
</script>

<style scoped lang="scss">
.newSelectCom {
  display: flex;
  align-items: center;

  .radioGroup {
    .el-radio-button:last-child {
      color: red;

      .el-radio-button__inner {
        border: none !important;
      }
    }
  }

  .search {
    width: 250px;
  }

  .btn {
    font-size: 14px;
    display: inline-block;
    margin-left: 40px;
  }

  .handleTagBtn {
    color: $color-sub-text;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      color: $color-highlight-background;
    }
  }

  .resetBtn {
    color: $color-text-l;

    .text {
      color: #2e6ea5;
      font-weight: bold;
      display: inline-block;
      vertical-align: baseline;
    }
  }
}
</style>
<style lang="scss">
.newSelectCom {
  .el-input--small .el-input__inner {
    line-height: 28px;
    height: 28px;
  }
  .search {
    width: 250px;
    .el-input__inner{
      background:rgba(218,218,218,0.13);
      border-radius:8px;
    }
  }
}
</style>
