<template>
  <div class="personaWrap" v-loading="personaLoading">
    <portrayal v-show="!noData" :userProfileData="userProfileData" />
  </div>
</template>

<script setup>
import portrayal from "./portrayal.vue";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
const store = useStore();
const params = reactive({
  mKolid: store.state.kolDetails.kolMkolId,
  mon: store.state.month,
});
const userProfileData = computed(() => store.state.kolDetails.userProfileData);
const personaLoading = computed(() => store.state.kolDetails.personaLoading);
onMounted(() => {
  store.dispatch("kolDetails/getPersona", params);
});
</script>

<style scoped lang="scss">
.personaWrap {
  margin-top: 10px;
}
</style>
