<template>
  <div class="colNormalTableCom">
    <el-table
      v-if="showTable"
      ref="multipleTable"
      :data="tempTableData"
      stripe
      :height="tableHeight"
      :header-cell-style="headerStyle"
      :cell-style="cellStyle"
      @select-all="selectAll"
      :expand-row-keys="expandFunc"
      row-key="onlyId"
      @select="selectChange"
      size="mini"
    >
      <!--
            第一列：选择框
            第二列：排名
            第三列：名称
            第四列：类别
            排序列
            最后一列：操作
            -->
      <el-table-column width="25" type="expand" fixed>
        <template #default="{ row }">
          <div
            class="expandWrapper"
            style="background-color: rgb(240, 245, 255); border-radius: 5px"
          >
            <div class="tagWrapDiv" style="padding: 5px 10px">
              <template v-if="row.label">
                <el-tag
                  type="warning"
                  closable
                  v-for="(tag, index) in row.label"
                  :key="Math.random() + index"
                  :disable-transitions="false"
                  @close="deleteLabel(tag, row)"
                >
                  {{ tag }}
                </el-tag>
              </template>
              <i
                @click="addNewLabel(row)"
                style="
                  margin-left: 10px;
                  font-size: 24px;
                  cursor: pointer;
                  vertical-align: middle;
                "
                class="el-icon-circle-plus hoverI"
              ></i>
            </div>
            <div style="margin-bottom: 6px; padding: 5px 10px">
              <el-table
                :data="buildExpandTableData(row)"
                :header-cell-style="rowClass"
                border
                style="width: 100%"
              >
                <el-table-column prop="priceInfo" align="center" label="价格">
                  <template #header>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <template #content>
                        <span
                          class="priceSpan blue"
                          style="margin-right: 10px"
                        ></span
                        >活动价格<br />
                        <span
                          class="priceSpan pink"
                          style="margin-right: 10px"
                        ></span
                        >用户上传价格<br />
                        <span
                          class="priceSpan green"
                          style="margin-right: 10px"
                        ></span
                        >刊例价<br />
                        <span
                          class="priceSpan yellow"
                          style="margin-right: 10px"
                        ></span
                        >拟合价
                      </template>
                      <svg-icon
                        icon-class="iconStyle"
                        class-name="question"
                      ></svg-icon>
                    </el-tooltip>
                    价格
                  </template>
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.priceInfo.length)"
                      :key="index"
                      v-for="(item, index) in row.priceInfo"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="price" align="center" label="价格">
                  <template #default="{ row }">
                    <p
                      :class="setBorderColorStyle(index, row)"
                      :key="index"
                      v-for="(item, index) in row.price"
                    >
                      {{ item.price }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="engagementRate"
                  align="center"
                  label="ER"
                >
                  <template #header>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <template #content>互动量 / 曝光数</template>
                      <svg-icon
                        icon-class="iconStyle"
                        class-name="question"
                      ></svg-icon>
                    </el-tooltip>
                    ER
                  </template>
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.engagementRate.length)"
                      :key="index"
                      v-for="(item, index) in row.engagementRate"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="postRead"
                  align="center"
                  label="单条阅读UV"
                >
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.postRead.length)"
                      :key="index"
                      v-for="(item, index) in row.postRead"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="cpuv" align="center" label="CPUV">
                  <template #header>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <template #content>单个UV触达成本</template>
                      <svg-icon
                        icon-class="iconStyle"
                        class-name="question"
                      ></svg-icon>
                    </el-tooltip>
                    CPUV
                  </template>
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.cpuv.length)"
                      :key="index"
                      v-for="(item, index) in row.cpuv"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="postEngagement"
                  align="center"
                  label="单条互动"
                >
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.postEngagement.length)"
                      :key="index"
                      v-for="(item, index) in row.postEngagement"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="cpe" align="center" label="CPE">
                  <template #header>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <template #content>单个互动成本</template>
                      <svg-icon
                        icon-class="iconStyle"
                        class-name="question"
                      ></svg-icon>
                    </el-tooltip>
                    CPE
                  </template>
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.cpe.length)"
                      :key="index"
                      v-for="(item, index) in row.cpe"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
                <!-- taPostRead -->
                <el-table-column
                  prop="postRead"
                  align="center"
                  label="单条TA UV"
                >
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.taPostRead.length)"
                      :key="index"
                      v-for="(item, index) in row.taPostRead"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="cpta" align="center" label="CPTA">
                  <template #header>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <template #content>单个TA触达成本</template>
                      <svg-icon
                        icon-class="iconStyle"
                        class-name="question"
                      ></svg-icon>
                    </el-tooltip>
                    CPTA
                  </template>
                  <template #default="{ row }">
                    <p
                      :class="setBorderStyle(index, row.cpta.length)"
                      :key="index"
                      v-for="(item, index) in row.cpta"
                    >
                      {{ item }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div
              v-if="isShowCoopInfo"
              style="margin-bottom: 6px; padding: 5px 10px"
            >
              <span style="font-size: 16px">历史合作信息</span>
              <el-table
                :data="buildHistoryInfoTableData(row)"
                border
                style="width: 100%; margin-top: 10px"
              >
                <el-table-column
                  align="center"
                  prop="coopCostAvg"
                  label="合作均价"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="coopCpuv"
                  label="合作平均CPUV"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="coopCpe"
                  label="合作平均CPE"
                >
                </el-table-column>
                <el-table-column
                  prop="coopCount"
                  align="center"
                  label="合作次数"
                >
                </el-table-column>
                <el-table-column
                  prop="coopCampaignType"
                  align="center"
                  label="合作类型"
                >
                </el-table-column>
              </el-table>
            </div>

            <div
              v-if="isShowCoopInfo"
              style="margin-bottom: 6px; padding: 5px 10px"
            >
              <span style="font-size: 16px">最近6个月合作</span>
              <el-table
                :data="buildCoopInfoData(row)"
                border
                style="width: 100%; margin-top: 10px"
              >
                <el-table-column prop="brand" align="center" label="品牌">
                  <template v-slot="scoped">
                    {{ scoped.row.brand === null ? "--" : scoped.row.brand }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="campaignName"
                  align="center"
                  label="活动"
                >
                  <template v-slot="scoped">
                    {{
                      scoped.row.campaignName === null
                        ? "--"
                        : scoped.row.campaignName
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="postTime"
                  align="center"
                  label="发帖时间"
                >
                  <template v-slot="scoped">
                    {{
                      scoped.row.postTime === null ? "--" : scoped.row.postTime
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="pourlstTime"
                  align="center"
                  label="推文链接"
                  showOverflowTooltip
                >
                  <template v-slot="scoped">
                    <a
                      style="color: rgb(81, 166, 240)"
                      target="_blank"
                      :href="scoped.row.url"
                      >{{ scoped.row.url }}</a
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="postType"
                  align="center"
                  label="发帖形式"
                >
                  <template v-slot="scoped">
                    {{
                      scoped.row.postType === null
                        ? "--"
                        : postTypeToZn(scoped.row.postType)
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="uv" align="center" label="UV">
                  <template v-slot="scoped">
                    {{
                      scoped.row.uv === null
                        ? "--"
                        : sliceDecimal(scoped.row.uv)
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="engagement"
                  align="center"
                  label="互动量"
                >
                  <template v-slot="scoped">
                    {{
                      scoped.row.engagement === null
                        ? "--"
                        : sliceDecimal(scoped.row.engagement)
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="cost" align="center" label="合作价格">
                  <template v-slot="scoped">
                    {{
                      scoped.row.cost === null
                        ? "--"
                        : sliceDecimal(scoped.row.cost)
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="cpe" align="center" label="CPE">
                  <template v-slot="scoped">
                    {{ scoped.row.cpe === null ? "--" : scoped.row.cpe }}
                  </template>
                </el-table-column>
                <el-table-column prop="cpv" align="center" label="CPUV">
                  <template v-slot="scoped">
                    {{ scoped.row.cpv === null ? "--" : scoped.row.cpv }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="agencyName"
                  align="center"
                  label="供应商名称"
                >
                  <template v-slot="scoped">
                    {{
                      scoped.row.agencyName === null
                        ? "--"
                        : scoped.row.agencyName
                    }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
        width="30"
        align="left"
        fixed
        class-name="selectStyle"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="KOL名称"
        align="left"
        :show-overflow-tooltip="true"
        fixed
        width="150"
      >
        <template #default="{ row }">
          <div style="display: flex; align-items: center">
            <img
              style="
                border: 1px solid #fff;
                border-radius: 50%;
                width: 28px;
                height: 28px;
              "
              :src="row.logoUrl"
              alt=""
            />
            <el-tooltip
              v-if="row.activeViewerNum === null"
              effect="dark"
              placement="top"
            >
              <template #content> KOL期间内未发布内容</template>
              <svg-icon class-name="question" class="questionClass"></svg-icon>
            </el-tooltip>

            <span class="name ellipsis" @click="nameClick(row)">
              {{ row.name }}
            </span>
          </div>
        </template>
      </el-table-column>
      <!--排序列-->
      <el-table-column
        v-for="(item, index) in colData"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="setWidthByIndex(item)"
        align="left"
        :label-class-name="item.isRank && isHighLight ? 'colActClass' : ''"
        :class-name="item.isRank && isHighLight ? 'colActClass' : ''"
      >
        <!--表头-->
        <template #header>
          <div
            v-if="item.canRank"
            class="clickClass"
            :class="item.isRank && isHighLight ? 'colActClass' : ''"
            @click="sortClick(item.prop, item.label)"
          >
            <div v-if="item.label === '近4周触达UV'">
              <el-tooltip
                placement="top"
                effect="light"
                :content="item.info"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="lineheight: 23px">近4周</p>
                  <p style="lineheight: 23px">触达人数</p>
                </div>
              </el-tooltip>
            </div>
            <!-- seeding score 1 -->
            <!-- <div v-else-if="item.label === 'seeding score 1'">
                          <p style="line-height: 23px">seeding</p>
                          <p style="line-height: 23px">score 1</p>
                        </div>
                        <div v-else-if="item.label === 'seeding score 2'">
                          <p style="line-height: 23px">seeding</p>
                          <p style="line-height: 23px">score 2</p>
                        </div> -->
            <div v-else-if="item.label === '周作品数量'">
              <el-tooltip
                placement="top"
                :content="item.info"
                effect="light"
                popper-class="popper"
              >
                <p>周作品数量</p>
              </el-tooltip>
            </div>
            <div v-else-if="item.label === '单条阅读UV'">
              <el-tooltip
                placement="top"
                :content="item.info"
                effect="light"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="lineheight: 23px">单条</p>
                  <p style="lineheight: 23px">阅读人数</p>
                </div>
              </el-tooltip>
            </div>
            <div v-else-if="item.label === '单条互动'">
              <el-tooltip
                placement="top"
                :content="item.info"
                effect="light"
                popper-class="popper"
              >
                <p>单条互动</p>
              </el-tooltip>
            </div>
            <div v-else-if="item.label === '单条阅读趋势'">
              <el-tooltip
                placement="top"
                :content="item.info"
                effect="light"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="lineheight: 23px">单条</p>
                  <p style="lineheight: 23px">阅读趋势</p>
                </div>
              </el-tooltip>
            </div>
            <div v-else-if="item.label === '粉丝活跃度指数'">
              <el-tooltip placement="top" effect="light" popper-class="popper">
                <template #content>
                  {{ item.info }}<br />
                  粉丝活跃度指数基准值:
                  <span style="color: #67c23a">{{
                    benchmarkValue && benchmarkValue.fansActiveIndex !== null
                      ? benchmarkValue.fansActiveIndex
                      : "--"
                  }}</span>
                </template>
                <div style="padding: 0">
                  <p style="lineheight: 23px">粉丝</p>
                  <p style="lineheight: 23px">活跃度指数</p>
                </div>
              </el-tooltip>
            </div>
            <div v-else-if="item.label === '作品水量指数'">
              <el-tooltip placement="top" effect="light" popper-class="popper">
                <template #content>
                  {{ item.info }}<br />
                  作品水量指数基准值:
                  <span style="color: #67c23a">{{
                    benchmarkValue.waterIndex !== null
                      ? benchmarkValue.waterIndex
                      : "--"
                  }}</span>
                </template>
                <div style="padding: 0">
                  <p style="lineheight: 23px">作品</p>
                  <p style="lineheight: 23px">水量指数</p>
                </div>
              </el-tooltip>
            </div>
            <div v-else-if="item.label === '内容质量指数'">
              <el-tooltip placement="top" effect="light" popper-class="popper">
                <!-- <template #content>
                  {{ item.info }}<br />
                  内容质量指数基准值:
                  <span style="color: #67c23a">{{
                    benchmarkValue &&
                    benchmarkValue.contentQualityIndex !== null
                      ? benchmarkValue.contentQualityIndex
                      : "--"
                  }}</span>
                </template> -->
                <template
                  #content
                  v-if="platform !== 'douyin' && platform !== 'xiaohongshu'"
                >
                  <span
                    >达人优质互动（收藏/在看+优质评论数量）占比除以平台优质互动占比均值</span
                  ><br />
                  <p v-if="platform === 'douyin' || platform === 'xiaohongshu'">
                  内容质量指数基准值:
                  <span style="color: #67c23a">{{
                    benchmarkValue &&
                    benchmarkValue.contentQualityIndex !== null
                      ? benchmarkValue.contentQualityIndex
                      : "--"
                    }}</span></p>
                </template>
                <template #content v-else>
                  <span
                    >GPT技术识别用户评论中对内容质量的正面评论，加权评论获赞获评后计算的指数</span
                  >
                </template>
                <div style="padding: 0">
                  <p style="line-height: 23px">内容</p>
                  <p style="line-height: 23px">质量指数</p>
                </div>
              </el-tooltip>
            </div>
            <el-tooltip
              v-else-if="item.info !== null && item.info"
              placement="top"
              :content="item.info"
              effect="light"
              popper-class="popper"
            >
              <span>{{ item.label }}</span>
            </el-tooltip>
            <span v-else>{{ item.label }}</span>
          </div>
          <div v-else-if="item.label === '级别'">
            <el-tooltip placement="top"  effect="light" popper-class="popper">
              <template #content>
                Tier1(平台top5%)<br />
                Tier2(平台5~20%)<br />
                Tier3(平台20~50%)<br />
                KOC(平台50~100%)<br />
              </template>
              <p style="line-height: 22px">级别</p>
            </el-tooltip>
          </div>
          <div v-else-if="item.label === '水量程度'">
            <el-tooltip placement="top"  effect="light" popper-class="popper">
              <template #content>
               <div v-if="platform === 'douyin'">
                 <p>绿灯:作品水量指数&lt;12</p>
                 <p>黄灯:作品水量指数≥12</p>
               </div>
                <div v-else-if="platform === 'wechat'">
                  <p>绿灯:作品水量指数&lt;2.4</p>
                  <p>黄灯:2.4≤作品水量指数&lt;5</p>
                  <p>红灯:作品水量指数≥5</p>
                </div>
                <div v-if="platform === 'weibo'">
                  <p>绿灯:作品水量指数&lt;2</p>
                  <p>黄灯:2≤作品水量指数&lt;3.5</p>
                  <p>红灯:作品水量指数≥3.5</p>
                </div>
                <div v-if="platform === 'xiaohongshu'">
                  <p>绿灯:作品水量指数&lt;6</p>
                  <p>黄灯:6≤作品水量指数&lt;12</p>
                  <p>红灯:作品水量指数≥12</p>
                </div>
                <div v-if="platform === 'bilibili'">
                  <p>绿灯:作品水量指数&lt;15</p>
                  <p>黄灯:作品水量指数≥15</p>
                </div>
                <div v-if="platform === 'kuaishou'">
                  <p>绿灯:默认绿灯</p>
                </div>
              </template>
              <p>水量程度</p>
            </el-tooltip>
          </div>
          <span v-else>
            {{ item.label }}
          </span>
        </template>
        <!--表格-->
        <template #default="{ row }">
          <div v-if="item.prop === 'waterAlter'">
            <el-tooltip v-if="row[item.prop] === 3" placement="top" popper-class="popper">
              <template #content>
                {{row['waterRatio']? (row['waterRatio']*100).toFixed(2) + '%' : "0%"}}
              </template>
            <svg-icon  style="font-size:20px;" class-name="redLightIcon" />
            </el-tooltip>
            <el-tooltip v-else-if="row[item.prop] === 2" placement="top" popper-class="popper">
              <template #content>
                {{row['waterRatio']? (row['waterRatio']*100).toFixed(2) + '%' : "0%"}}
              </template>
              <svg-icon  style="font-size:20px;" class-name="yellowLightIcon" />
            </el-tooltip>
            <el-tooltip v-else-if="row[item.prop] === 1" placement="top" popper-class="popper">
              <template #content>
                {{row['waterRatio']? (row['waterRatio']*100).toFixed(2) + '%' : "0%"}}
              </template>
              <svg-icon  style="font-size:20px;" class-name="greenLightIcon" />
            </el-tooltip>
            <span v-else>--</span>
          </div>
          <div v-else>
            {{ row[item.prop] === null ? "--" : sliceDecimal(row[item.prop]) }}
          </div>
        </template>
      </el-table-column>
      <!--操作列-->
      <el-table-column
        v-if="showHandle"
        prop="handle"
        label="操作"
        width="50"
        align="center"
      >
        <template #default="{ row }">
          <el-tooltip effect="dark" content="删除" placement="top">
            <i
              class="el-icon-delete"
              style="cursor: pointer"
              @click="openDelKolDialog(row.id)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="新增标签"
      @close="cancelAddTag"
      v-model="tagDialogVisible"
      width="30%"
    >
      <el-select
        v-model="tagValue"
        multiple
        filterable
        allow-create
        default-first-option
        @change="changeNewLabel"
        @blur="blurNewLabel"
        placeholder="请选择"
      >
        <el-option
          v-for="item in tagOptions"
          :key="item.label"
          :label="item.label"
          :value="item.label"
        >
        </el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelAddTag">取 消</el-button>
          <el-button type="primary" @click="addNewTag">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { delKolLabel, addNewKolLabel } from "@/api/rankingList";

export default {
  name: "tableV2",
  data() {
    return {
      limit: 25,
      checkBoxList: [],
      showTable: false,
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
        overflow: "hidden",
      },
      cellStyle: {
        fontSize: "12px",
      },
      colData: [],
      rankCol: "activeViewerNum",
      tableHeight: 600,
      originRankingList: [
        {
          canRank: 0,
          info: null,
          isRank: 0,
          label: "类型",
          prop: "kolCategory",
          type: "String",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: `Tier1(平台top5%)
                 Tier2(平台5~20%)
                 Tier3(平台20~50%)
                 KOC(平台50~100%)
          `,
          isRank: 0,
          label: "级别",
          order_id: 30,
          prop: "tier",
          round: null,
          type: "int",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人主页粉丝数",
          isRank: 0,
          label: "粉丝数",
          order_id: 4,
          prop: "kolFans",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周文章阅读数/视频浏览数去水去重UV数",
          isRank: 1,
          label: "近4周触达UV",
          order_id: 5,
          prop: "activeViewerNum",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周周均发布文章/视频数",
          isRank: 0,
          label: "周作品数量",
          order_id: 10,
          prop: "postCount",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条文章/视频去水阅读UV数中位数",
          isRank: 0,
          label: "单条阅读UV",
          order_id: 11,
          prop: "postRead",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条文章/视频去水互动（转评赞藏等加总）中位数",
          isRank: 0,
          label: "单条互动",
          order_id: 6,
          prop: "postEngagement",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条阅读UV中位数除以近12周单条阅读UV",
          isRank: 0,
          label: "单条阅读趋势",
          order_id: 6,
          prop: "postTrafficTrend",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人播粉比（单条阅读UV/粉丝量）除以平台达人播粉比中位数",
          isRank: 0,
          label: "粉丝活跃度指数",
          order_id: 7,
          prop: "fansActiveIndex",
          round: 0,
          type: "Percent",
        },
        // 有效流量指数
        // 作品水量指数
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人文章/视频平均水量除以平台平均水量",
          isRank: 0,
          label: "作品水量指数",
          order_id: 7,
          prop: "waterIndex",
          round: 0,
          type: "Percent",
          // formatter: (row, column, cellValue, index) => {
          //   return cellValue === null ? '--' : this.sliceDecimal(cellValue)
          // }
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人优质互动（收藏/在看+优质评论数量）占比除以平台优质互动占比均值",
          isRank: 0,
          label: "内容质量指数",
          order_id: 7,
          prop: "contentQualityIndex",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人受众中TA人数占TA人群总量比例",
          isRank: 0,
          label: "TA覆盖度%",
          order_id: 7,
          prop: "taPct",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人受众中TA人群占比",
          isRank: 0,
          label: "TA浓度%",
          order_id: 8,
          prop: "taRatio",
          round: 2,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人TA浓度除以平台TA浓度",
          isRank: 0,
          label: "TA TGI",
          order_id: 9,
          prop: "taTgi",
          round: 0,
          type: "Double",
        },
      ],
      rankingListV2: [
        {
          canRank: 0,
          info: null,
          isRank: 0,
          label: "类型",
          prop: "kolCategory",
          type: "String",
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: `Tier1(平台top5%)
                 Tier2(平台5~20%)
                 Tier3(平台20~50%)
                 KOC(平台50~100%)
          `,
          isRank: 0,
          label: "级别",
          order_id: 30,
          prop: "tier",
          round: null,
          type: "int",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人主页粉丝数",
          isRank: 0,
          label: "粉丝数",
          order_id: 4,
          prop: "kolFans",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周文章阅读数/视频浏览数去水去重UV数",
          isRank: 1,
          label: "近4周触达UV",
          order_id: 5,
          prop: "activeViewerNum",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周周均发布文章/视频数",
          isRank: 0,
          label: "周作品数量",
          order_id: 10,
          prop: "postCount",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条文章/视频去水阅读UV数中位数",
          isRank: 0,
          label: "单条阅读UV",
          order_id: 11,
          prop: "postRead",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条文章/视频去水互动（转评赞藏等加总）中位数",
          isRank: 0,
          label: "单条互动",
          order_id: 6,
          prop: "postEngagement",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "近4周单条阅读UV中位数除以近12周单条阅读UV",
          isRank: 0,
          label: "单条阅读趋势",
          order_id: 6,
          prop: "postTrafficTrend",
          round: 0,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人播粉比（单条阅读UV/粉丝量）除以平台达人播粉比中位数",
          isRank: 0,
          label: "粉丝活跃度指数",
          order_id: 7,
          prop: "fansActiveIndex",
          round: 0,
          type: "Percent",
        },
        // 有效流量指数
        // 作品水量指数
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人文章/视频平均水量除以平台平均水量",
          isRank: 0,
          label: "作品水量指数",
          order_id: 7,
          prop: "waterIndex",
          round: 0,
          type: "Percent",
          // formatter: (row, column, cellValue, index) => {
          //   return cellValue === null ? '--' : this.sliceDecimal(cellValue)
          // }
        },
        {
          canRank: 0,
          color: null,
          icon: null,
          info: "水量程度",
          isRank: 0,
          label: "水量程度",
          order_id: 7,
          prop: "waterAlter",
          round: 0,
          type: "Percent",
          // formatter: (row, column, cellValue, index) => {
          //   return cellValue === null ? '--' : this.sliceDecimal(cellValue)
          // }
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人优质互动（收藏/在看+优质评论数量）占比除以平台优质互动占比均值",
          isRank: 0,
          label: "内容质量指数",
          order_id: 7,
          prop: "contentQualityIndex",
          round: 0,
          type: "Percent",
        },
        // {
        //   canRank: 1,
        //   color: null,
        //   icon: null,
        //   info: "达人受众中TA人数占TA人群总量比例",
        //   isRank: 0,
        //   label: "TA覆盖度%",
        //   order_id: 7,
        //   prop: "taPct",
        //   round: 0,
        //   type: "Percent",
        // },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人受众中TA人群占比",
          isRank: 0,
          label: "TA浓度%",
          order_id: 8,
          prop: "taRatio",
          round: 2,
          type: "Percent",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "达人TA浓度除以平台TA浓度",
          isRank: 0,
          label: "TA TGI",
          order_id: 9,
          prop: "taTgi",
          round: 0,
          type: "Double",
        },
      ],
      tempScope: {},
      tagDialogVisible: false,
      tagValue: [],
      tempTableData: [],
      isShowCoopInfo: false,
      isAddSeedscoreHead: false,
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    showHandle: {
      type: Boolean,
      default: false,
    },
    benchmarkValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    allTagOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    seedingScoreHeads: {
      type: Array,
      default: () => {
        return [];
      },
    },
    platform: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters([
      "siteValue",
      "colSiteValue",
      "isHighLight",
      "isExpand",
      "menuList",
    ]),
    expandFunc() {
      if (!this.isExpand) {
        return [];
      } else {
        return this.tableData.map((v) => {
          return v.mKolid;
        });
      }
    },
    site() {
      return this.$route.name === "RankingListV2"
        ? this.siteValue
        : this.colSiteValue;
    },
    priceInfo() {
      switch (this.site) {
        case "wechat":
          return ["一推", "二推"];
        case "weibo":
          return ["直发", "转发"];
        case "douyin":
          return ["视频21-60s", "视频1-20s"];
        case "xiaohongshu":
          return ["图文笔记", "视频笔记"];
        case "bilibili":
          return ["视频定制", "视频植入"];
        case "kuaishou":
          return ["原创视频"];
        default:
          return ["默认值"];
      }
    },
    tagOptions() {
      if (this.tempScope.label) {
        let res = this.allTagOptions;
        this.tempScope.label.forEach((tag) => {
          res = res.filter((v) => v.label !== tag);
        });
        return res;
      } else {
        return this.allTagOptions;
      }
    },
  },
  mounted() {
    if (this.$route.path.includes("/campaign")) {
      this.tableHeight = document.body.clientHeight - 550;
    } else if (this.$route.path !== "/rankingListV2") {
      this.tableHeight = document.body.clientHeight - 425;
    } else {
      this.tableHeight = document.body.clientHeight - 350;
    }
    // this.$refs.multipleTable.doLayout();
  },
  methods: {
    ...mapMutations({
      setIsHighLight: "SET_ISHIGHLIGHT",
    }),
    setBorderColorStyle(index, scope) {
      if (index === scope.price.length - 1) {
        switch (scope.price[index].color) {
          case "pred":
            return "noBorder yellowText";
          case "crawl":
            return "noBorder greenText";
          case "campaign":
            return "noBorder blueText";
          case "custom":
            return "noBorder pinkText";
          default:
            return "noBorder";
        }
      } else {
        switch (scope.price[index].color) {
          case "pred":
            return "haveBorder yellowText";
          case "crawl":
            return "haveBorder greenText";
          case "campaign":
            return "haveBorder blueText";
          case "custom":
            return "haveBorder pinkText";
          default:
            return "haveBorder";
        }
      }
    },
    setBorderStyle(index, len) {
      if (index === len - 1) {
        return "noBorder";
      } else {
        return "haveBorder";
      }
    },
    buildExpandTableData(v) {
      return [
        {
          adProportion: v.adProportion ? v.adProportion : "0%",
          coopBrand: v.coopBrand ? v.coopBrand.split(",") : ["--"],
          price:
            this.siteValue === "kuaishou"
              ? [
                  {
                    price:
                      v.price1 !== null
                        ? `¥ ${this.sliceDecimal(v.price1)}`
                        : "--",
                    color: v.priceSource1,
                  },
                ]
              : [
                  {
                    price:
                      v.price1 !== null
                        ? `¥ ${this.sliceDecimal(v.price1)}`
                        : "--",
                    color: v.priceSource1,
                  },
                  {
                    price:
                      v.price2 !== null
                        ? `¥ ${this.sliceDecimal(v.price2)}`
                        : "--",
                    color: v.priceSource2,
                  },
                ],
          engagementRate:
            this.siteValue === "kuaishou"
              ? [v.engagementRate1 ? v.engagementRate1 : "--"]
              : [
                  v.engagementRate1 ? v.engagementRate1 : "--",
                  v.engagementRate2 ? v.engagementRate2 : "--",
                ],
          cpuv:
            this.siteValue === "kuaishou"
              ? [v.cpuv1 !== null ? "¥" + this.sliceDecimal(v.cpuv1) : "--"]
              : [
                  v.cpuv1 !== null ? "¥" + this.sliceDecimal(v.cpuv1) : "--",
                  v.cpuv2 !== null ? "¥" + this.sliceDecimal(v.cpuv2) : "--",
                ],
          postRead:
            this.siteValue === "kuaishou"
              ? [v.postRead1 !== null ? this.sliceDecimal(v.postRead1) : "--"]
              : [
                  v.postRead1 !== null ? this.sliceDecimal(v.postRead1) : "--",
                  v.postRead2 !== null ? this.sliceDecimal(v.postRead2) : "--",
                ],
          taPostRead:
            this.siteValue === "kuaishou"
              ? [
                  v.taPostRead1 !== null
                    ? this.sliceDecimal(v.taPostRead1)
                    : "--",
                ]
              : [
                  v.taPostRead1 !== null
                    ? this.sliceDecimal(v.taPostRead1)
                    : "--",
                  v.taPostRead2 !== null
                    ? this.sliceDecimal(v.taPostRead2)
                    : "--",
                ],
          postEngagement:
            this.siteValue === "kuaishou"
              ? [
                  v.postEngagement1 !== null
                    ? this.sliceDecimal(v.postEngagement1)
                    : "--",
                ]
              : [
                  v.postEngagement1 !== null
                    ? this.sliceDecimal(v.postEngagement1)
                    : "--",
                  v.postEngagement2 !== null
                    ? this.sliceDecimal(v.postEngagement2)
                    : "--",
                ],
          cpe:
              this.siteValue === "kuaishou"
                  ? [v.cpe1 ? "¥" + this.sliceDecimal(v.cpe1) : "--"]
                  : [v.cpe1 ? "¥" + this.sliceDecimal(v.cpe1) : "--", v.cpe2 ? "¥" + this.sliceDecimal(v.cpe2) : "--"],
          cpta:
              this.siteValue === "kuaishou"
                  ? [v.cpta1 ? "¥" + this.sliceDecimal(v.cpta1) : "--"]
                  : [
                    v.cpta1 ? "¥" + this.sliceDecimal(v.cpta1) : "--",
                    v.cpta2 ? "¥" + this.sliceDecimal(v.cpta2) : "--",
                  ],
          priceInfo: this.priceInfo,
        },
      ];
    },
    buildHistoryInfoTableData(v) {
      let temparr = [];
      temparr = [
        {
          coopCostAvg: v.coopCostAvg
            ? "¥" + this.sliceDecimal(v.coopCostAvg)
            : "--",
          coopCpuv: v.coopCpuv ? "¥" + this.sliceDecimal(v.coopCpuv) : "--",
          coopCpe: v.coopCpe ? "¥" + this.sliceDecimal(v.coopCpe) : "--",
          coopCount: v.coopCount ? v.coopCount : "--",
          coopCampaignType: v.coopCampaignType ? v.coopCampaignType : "--",
        },
      ];
      this.historyInfoTableData = temparr;
      return temparr;
    },
    buildCoopInfoData(v) {
      return v.kolLatestCoopInfoList;
    },
    // 能删除某个label表示label有数据
    // 删除某个label
    async deleteLabel(tag, scope) {
      // 删除label
      let { code } = await delKolLabel({
        labelName: tag,
        mKolid: scope.mKolid,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "删除标签成功!",
        });
        this.$emit("reRenderTagShow");
      } else {
        this.$message.error("删除标签失败!");
      }
    },
    addNewLabel(scope) {
      this.tagValue = [];
      this.tempScope = scope;
      this.tagDialogVisible = true;
    },
    changeNewLabel(val) {
      // 可多选  可输入  可筛选
      // 输入为空时不生成tag
      this.tagValue = val.map((item) => item.trim());
      this.tagValue = this.tagValue.filter((item) => item.trim() !== "");
    },
    blurNewLabel(e) {
      const inputVal = e.target.value.trim();
      if (inputVal) {
        this.tagValue.push(inputVal);
      }
    },
    cancelAddTag() {
      this.tagValue = [];
      this.tempScope = {};
      this.tagDialogVisible = false;
    },
    async addNewTag() {
      // 判断新增标签已存不存在
      if (this.tempScope.label) {
        for (let i = 0; i < this.tagValue.length; i++) {
          if (this.tempScope.label.includes(this.tagValue[i])) {
            this.$message({
              type: "info",
              message: "新增标签已经存在，请重新填写!",
            });
            return;
          }
        }
      }
      let labelNames = this.tempScope.label
        ? this.tempScope.label.concat(this.tagValue)
        : this.tagValue;
      let { code } = await addNewKolLabel({
        labelNames,
        mKolid: this.tempScope.mKolid,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "新增标签成功!",
        });
        // 触发更新，重新渲染tagShow 组件
        this.$emit("reRenderTagShow");
      } else {
        this.$message.error("新增标签失败!");
      }
      this.cancelAddTag();
    },
    setWidthByIndex(item) {
      // if
      // // (item.label === "TA TGI") {
      // //   return 55;
      // // } else if (item.label === "类型" || item.label === "级别") {
      // //   return 38;
      // // } else if
      //   (item.label === "周作品数量") {
      //   return 70;
      // } else if (
      //   item.prop === "seedingScore1" ||
      //   item.prop === "seedingScore2"
      // ) {
      //   return 135;
      // } else {
        return null;
      // }
    },
    sortClick(value, label) {
      if (!this.isHighLight) {
        this.setIsHighLight(true);
      }
      this.rankCol = value;
      this.choseColData();
      this.$emit("changeSort", value, label,false);
    },
    nameClick(row) {
      // 改为弹窗模式，就向上广播
      this.$emit("openDetail", row.mKolid, row.name);
    },
    selectChange(item, row) {
      this.$emit(
        "checkEmpty",
        item.some((v) => {
          return (
            v.activeViewerNum === 0 ||
            v.activeViewerNum === undefined ||
            v.activeViewerNum === null
          );
        })
      );

      if (item.length > this.limit) {
        this.$refs.multipleTable.toggleRowSelection(row);
        this.checkBoxList = item.slice(0, this.limit);
        this.checkboxValueChange(true);
      } else {
        this.checkBoxList = item;
        this.checkboxValueChange();
      }
    },
    selectAll(item) {
      this.$emit(
        "checkEmpty",
        item.some((v) => {
          return (
            v.activeViewerNum === 0 ||
            v.activeViewerNum === undefined ||
            v.activeViewerNum === null
          );
        })
      );

      this.$refs.multipleTable.clearSelection();
      if (this.checkBoxList.length === this.limit) {
        this.checkBoxList = [];
      } else {
        this.checkBoxList = item.slice(0, this.limit);
        this.checkBoxList.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item, true);
        });
      }
      this.checkboxValueChange();
    },
    checkboxValueChange(bool = false) {
      if (bool) {
        this.$emit("checkboxValueChange", this.checkBoxList, bool);
      } else {
        this.$emit("checkboxValueChange", this.checkBoxList);
      }
    },
    choseColData() {
      this.rankingListV2.forEach((v) => {
        v.isRank = 0;
        if (v.prop === this.rankCol) {
          v.isRank = 1;
        }
      });
      this.colData = this.rankingListV2;
    },

    openDelKolDialog(id) {
      this.$emit("openDelKolDialog", id);
    },
    rowClass({ column, rowIndex, columnIndex }) {
      const tableHeaderStyle = {
        height: "35px",
        lineHeight: "35px",
      };
      if (rowIndex === 0 && columnIndex === 0) {
        this.$nextTick(() => {
          document
            .getElementsByClassName(column.id)[0]
            .setAttribute("colSpan", 2);
        }); //  一定要写在加载完毕后，nextTick 更新的最晚，才能获取到dom节点。
      }
      if (rowIndex === 0 && columnIndex === 1) {
        return { display: "none" };
      }
      return tableHeaderStyle;
    },
    // rowClassCoop() {
    //   return {
    //     height: "35px",
    //     lineHeight: "35px",
    //   };
    // },
  },
  watch: {
    menuList: {
      handler(val) {
        if (val) {
          let tempArr = val.find((v) => v.id === 410);
          if (tempArr) {
            let isShowCoopInfo = tempArr.children.find((v) => v.id === 430);
            if (isShowCoopInfo) {
              this.isShowCoopInfo = true;
            } else {
              this.isShowCoopInfo = false;
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
    seedingScoreHeads() {
      if (!this.isAddSeedscoreHead && this.seedingScoreHeads.length !== 0) {
        this.isAddSeedscoreHead = true;
        this.rankingListV2.push(...this.seedingScoreHeads);
      }
    },
    tableData(newVal) {
      this.showTable = false;
      // 将label从string改为数组
      newVal.forEach((v) => {
        if (v.label && typeof v.label === "string") {
          v.label = v.label.split("|");
        }
      });
      this.choseColData();
      setTimeout(() => {
        this.showTable = true;
      }, 20);
      const len = this.checkBoxList.length;
      if (len) {
        setTimeout(() => {
          for (let item of this.checkBoxList) {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        }, 50);
      }
      setTimeout(() => {
        this.$refs.multipleTable.doLayout();
      }, 100);
      this.tempTableData = newVal.map((item, index) => {
        item.onlyId = index;
        return item;
      });
    },
    site: {
      handler() {
        this.rankCol = "activeViewerNum";
        this.choseColData();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.colNormalTableCom {
  // overflow: auto;
  .expandWrapper table td,
  .expandWrapper table th {
    padding: 5px;
  }

  // .el-table__fixed-body-wrapper {
  //   top: 68px !important;
  // }

  .tagWrapDiv .hoverI {
    &:hover {
      color: #409eff;
    }
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .el-table__cell.el-table__expanded-cell {
    padding: 10px 40px;
  }

  .iconStyle {
    font-size: 12px;
    cursor: pointer;
    position: relative;
    top: -1px;
  }
}
</style>

<style scoped lang="scss">
.priceSpan {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.haveBorder {
  border-bottom: 1px solid #ebeef5;
}

.noBorder {
  border-bottom: none;
}

.green {
  background-color: #67c23a;
}

.yellow {
  background-color: #e6a23c;
}

.blue {
  background-color: #53bad2;
}

.pink {
  background-color: #f37092;
}

.priceGreen {
  color: #67c23a;
}

.priceYellow {
  color: #e6a23c;
}

.yellowText {
  color: #e6a23c;
}

.pinkText {
  color: #f37092;
}

.blueText {
  color: #51a6f0;
}

.greenText {
  color: #67c23a;
}

.name {
  cursor: pointer;
  margin-left: 6px;

  &:hover {
    color: #51a6f0;
  }
}

.questionClass {
  position: absolute;
  right: 0;
  top: 10px;
}
</style>
