<template>
  <div class="colNormalTableCom">
    <el-table
      v-if="showTable"
      ref="multipleTable"
      :data="tableData"
      stripe
      :height="tableHeight"
      :header-cell-style="headerStyle"
      :cell-style="cellStyle"
      @select-all="selectAll"
      :expand-row-keys="expandFunc"
      row-key="mKolid"
      @select="selectChange"
      size="mini"
    >
      <!--
      第一列：展开框
      第二列：选择框
      第三列：名称
      第四列：类别
      排序列
      最后一列：收藏（并非都显示）
      -->
      <el-table-column width="25" type="expand">
        <template #default="{ row }">
          <el-form inline class="demo-table-expand">
            <el-form-item>
              <template #label>
                <div>
                  <el-tooltip effect="dark" placement="top">
                    <template #content>
                      <span
                        class="priceSpan green"
                        style="margin-right: 5px"
                      ></span
                      >刊例价<br />
                      <span
                        class="priceSpan yellow"
                        style="margin-right: 5px"
                      ></span
                      >拟合价
                    </template>
                    <svg-icon
                      class="iconStyle"
                      class-name="question"
                    ></svg-icon>
                  </el-tooltip>
                  {{ priceBySite }}
                </div>
              </template>
              <span
                :class="
                  row.priceSource === 'crawl' ? 'priceGreen' : 'priceYellow'
                "
              >
                {{ row.price === null ? "--" : sliceDecimal(row.price * 1) }}
              </span>
            </el-form-item>
            <template v-if="site !== 'weibo'">
              <el-form-item>
                <template #label>
                  <el-tooltip
                    effect="dark"
                    content="互动量 / 曝光数"
                    placement="top"
                  >
                    <svg-icon
                      class-name="question"
                      class="iconStyle"
                    ></svg-icon>
                  </el-tooltip>
                  ER
                </template>
                <span>{{
                  row.engagementRate === null ? "--" : row.engagementRate
                }}</span>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <el-tooltip
                    effect="dark"
                    content="单个UV触达成本"
                    placement="top"
                  >
                    <svg-icon
                      class-name="question"
                      class="iconStyle"
                    ></svg-icon>
                  </el-tooltip>
                  CPI
                </template>
                <span>{{ row.cpi === null ? "--" : row.cpi }}</span>
              </el-form-item>
              <el-form-item>
                <template #label>
                  <el-tooltip
                    effect="dark"
                    content="单个TA触达成本"
                    placement="top"
                  >
                    <svg-icon
                      class-name="question"
                      class="iconStyle"
                    ></svg-icon>
                  </el-tooltip>
                  CPTA
                </template>
                <span>{{ row.cpta === null ? "--" : row.cpta }}</span>
              </el-form-item>
            </template>
            <el-form-item>
              <template #label>
                <div>
                  <el-tooltip
                    effect="dark"
                    content="单个互动成本"
                    placement="top"
                  >
                    <svg-icon
                      class="iconStyle"
                      class-name="question"
                    ></svg-icon>
                  </el-tooltip>
                  CPE
                </div>
              </template>
              <span>
                {{ row.cpe === null ? "N/A" : row.cpe }}
              </span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
        width="30"
        align="left"
        class-name="selectStyle"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="KOL名称"
        align="left"
        :show-overflow-tooltip="true"
        width="140"
      >
        <template #default="{ row }">
          <div style="display: flex; align-items: center">
            <img
              style="
                border: 1px solid #fff;
                border-radius: 50%;
                width: 28px;
                height: 28px;
              "
              :src="row.logoUrl"
              alt=""
            />
            <span class="name ellipsis" @click="nameClick(row)">
              {{ row.name }}
            </span>
          </div>
        </template>
      </el-table-column>
      <!--排序列-->
      <el-table-column
        v-for="(item, index) in colData"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        align="center"
        :width="setWidthByIndex(item)"
        :label-class-name="item.isRank && isHighLight ? 'colActClass' : ''"
        :class-name="item.isRank && isHighLight ? 'colActClass' : ''"
      >
        <!--表头-->
        <template #header>
          <div
            v-if="item.canRank"
            class="clickClass"
            :class="item.isRank && isHighLight ? 'colActClass' : ''"
            @click="sortClick(item.prop, item.label)"
          >
            <!--wechat-->
            <div v-if="item.label === '平均阅读水量'">
              <el-tooltip
                placement="top"
                :content="item.info"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="line-height: 23px">刷水</p>
                  <p style="line-height: 23px">文章水量</p>
                </div>
              </el-tooltip>
            </div>
            <!--weibo-->
            <div v-else-if="item.label === '月单篇平均互动'">
              <el-tooltip
                placement="top"
                :content="item.info"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="line-height: 23px">月单篇</p>
                  <p style="line-height: 23px">互动数</p>
                </div>
              </el-tooltip>
            </div>
            <!--douyin-->
            <div v-else-if="item.label === '月单篇曝光UV'">
              <el-tooltip
                placement="top"
                :content="item.info"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="line-height: 23px">月单篇</p>
                  <p style="line-height: 23px">曝光UV</p>
                </div>
              </el-tooltip>
            </div>
            <!--xiaohongshu-->
            <div v-else-if="item.label === '半年阅读刷水推文占比'">
              <el-tooltip
                placement="top"
                :content="item.info"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="line-height: 23px">半年刷水</p>
                  <p style="line-height: 23px">推文占比</p>
                </div>
              </el-tooltip>
            </div>
            <div v-else-if="item.label === '月单篇平均阅读UV'">
              <el-tooltip
                placement="top"
                :content="item.info"
                popper-class="popper"
              >
                <div style="padding: 0">
                  <p style="line-height: 23px">月单篇</p>
                  <p style="line-height: 23px">阅读UV</p>
                </div>
              </el-tooltip>
            </div>
            <!--other-->
            <el-tooltip
              v-else-if="item.info !== null"
              placement="top"
              :content="item.info"
              popper-class="popper"
            >
              <span>{{ item.label }}</span>
            </el-tooltip>
            <span v-else>{{ item.label }}</span>
          </div>
          <span v-else>
            {{ item.label }}
          </span>
        </template>
        <!--表格-->
        <template #default="{ row }">
          {{ row[item.prop] === null ? "--" : sliceDecimal(row[item.prop]) }}
        </template>
      </el-table-column>
      <!--操作列-->
      <el-table-column
        v-if="showHandle"
        prop="handle"
        label="操作"
        width="50"
        align="center"
      >
        <template #default="{ row }">
          <el-tooltip effect="dark" content="删除" placement="top">
            <i
              class="el-icon-delete"
              style="cursor: pointer"
              @click="openDelKolDialog(row.id)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "tableV1",
  data() {
    return {
      limit: 25,
      checkBoxList: [],
      showTable: false,
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
        overflow: "hidden",
      },
      cellStyle: {
        fontSize: "12px",
      },
      colData: [],
      rankCol: "muv",
      colHaveKolDialogVisible: false,
      delKOLid: "",
      tableHeight: 600,
      wechatColData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "类型",
          order_id: 3,
          prop: "category",
          round: null,
          type: "String",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月推文阅读用户去重去水总人数",
          isRank: 1,
          label: "月总阅读UV",
          order_id: 4,
          prop: "muv",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月首推（头条）推文去水后阅读人数",
          isRank: 0,
          label: "首推阅读",
          order_id: 5,
          prop: "viewMonthAvg1st",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "文章平均互动",
          isRank: 0,
          label: "月单篇互动数",
          order_id: 10,
          prop: "engagementMonthAvg",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月推文数量",
          isRank: 0,
          label: "月文章数量",
          order_id: 11,
          prop: "postCount",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "半年内刷水文章占半年内所有文章占比",
          isRank: 0,
          label: "半年阅读刷水推文占比",
          order_id: 6,
          prop: "fakePostPct",
          round: 0,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "半年内阅读刷水推文刷水量",
          isRank: 0,
          label: "平均阅读水量",
          order_id: 7,
          prop: "waterValueAvg",
          round: 0,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月读者对所选人群的覆盖度。公式：人群在KOL中匹配人数除以人群总人数×100%",
          isRank: 0,
          label: "TA%",
          order_id: 8,
          prop: "tarPct",
          round: 2,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月读者对所选人群的覆盖度相较平台的差异度。公式：TA%/(KOL近三个月总读者数/微信平台总人数)×100",
          isRank: 0,
          label: "TA TGI",
          order_id: 9,
          prop: "tarTgi",
          round: 0,
          type: "Double",
          width: 50,
        },
      ],
      weiboColData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "类型",
          order_id: 3,
          prop: "category",
          round: null,
          type: "String",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月推文转评赞去重去水总人数",
          isRank: 1,
          label: "月总互动",
          order_id: 4,
          prop: "mact",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "文章平均互动",
          isRank: 0,
          label: "月单篇平均互动",
          order_id: 5,
          prop: "postAct",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月推文数量",
          isRank: 0,
          label: "月文章数量",
          order_id: 11,
          prop: "postCount",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "账号主页显示的粉丝数",
          isRank: 0,
          label: "粉丝数",
          order_id: 6,
          prop: "kolFans",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "基于发帖特征、互粉关系、手机端行为等信息估算的假粉率",
          isRank: 0,
          label: "假粉率",
          order_id: 7,
          prop: "fansWater",
          round: 0,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL粉丝对所选人群的覆盖度。公式：人群在KOL中匹配人数除以人群总人数×100%",
          isRank: 0,
          label: "TA%",
          order_id: 8,
          prop: "tarPct",
          round: 2,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL粉丝对所选人群的覆盖度相较平台的差异度。公式：TA%/(KOL粉丝总数/微博平台总人数)×100",
          isRank: 0,
          label: "TA TGI",
          order_id: 9,
          prop: "tarTgi",
          round: 0,
          type: "Double",
          width: 50,
        },
      ],
      douyinColData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "类型",
          order_id: 3,
          prop: "category",
          round: null,
          type: "String",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月视频曝光用户去重去水总人数",
          isRank: 1,
          label: "月总曝光",
          order_id: 5,
          prop: "muv",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "视频平均互动",
          isRank: 0,
          label: "月单篇互动数",
          order_id: 12,
          prop: "postAct",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月推文数量",
          isRank: 0,
          label: "月文章数量",
          order_id: 13,
          prop: "postCount",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月视频转评赞去水总人数",
          isRank: 0,
          label: "月总互动",
          order_id: 6,
          prop: "mact",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "依据算法推算出的订阅用户单视频观看人数",
          isRank: 0,
          label: "月单篇曝光UV",
          order_id: 7,
          prop: "postExposure",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "账号主页显示的粉丝数",
          isRank: 0,
          label: "粉丝数",
          order_id: 8,
          prop: "kolFans",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "基于视频浏览行为估算的活跃粉丝比率",
          isRank: 0,
          label: "活跃粉丝%",
          order_id: 9,
          prop: "fansActivePct",
          round: 0,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月视频观者对所选人群的覆盖度。公式：人群在KOL中匹配人数除以人群总人数×100%",
          isRank: 0,
          label: "TA%",
          order_id: 10,
          prop: "tarPct",
          round: 2,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月视频观者对所选人群的覆盖度相较平台的差异度。公式：TA%/(KOL近三个月总观者数/抖音平台总人数)×100",
          isRank: 0,
          label: "TA TGI",
          order_id: 11,
          prop: "tarTgi",
          round: 0,
          type: "Double",
          width: 50,
        },
      ],
      xiaohongshuColData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "类型",
          order_id: 3,
          prop: "category",
          round: null,
          type: "String",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月笔记阅读用户去重总人数",
          isRank: 1,
          label: "阅读月UV",
          order_id: 4,
          prop: "muv",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月笔记去水后单篇阅读人数",
          isRank: 0,
          label: "月单篇平均阅读UV",
          order_id: 5,
          prop: "postUv",
          round: null,
          type: "Integer",
          width: 50,
        },

        {
          canRank: 1,
          color: null,
          icon: null,
          info: "笔记平均互动",
          isRank: 0,
          label: "月单篇互动数",
          order_id: 12,
          prop: "postAct",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月推文数量",
          isRank: 0,
          label: "月文章数量",
          order_id: 13,
          prop: "postCount",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "账号主页显示的粉丝数",
          isRank: 0,
          label: "粉丝数",
          order_id: 6,
          prop: "kolFans",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "基于阅读行为估算的活跃粉丝比率",
          isRank: 0,
          label: "活跃粉丝",
          order_id: 7,
          prop: "fansActivePct",
          round: 0,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "半年内刷水文章占半年内所有文章占比",
          isRank: 0,
          label: "半年阅读刷水推文占比",
          order_id: 8,
          prop: "fakePostPct",
          round: 0,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "半年内互动刷水笔记刷水量",
          isRank: 0,
          label: "互动水量",
          order_id: 9,
          prop: "waterValueAvg",
          round: 0,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月读者对所选人群的覆盖度。公式：人群在KOL中匹配人数除以人群总人数×100%",
          isRank: 0,
          label: "TA%",
          order_id: 10,
          prop: "tarPct",
          round: 2,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月读者对所选人群的覆盖度相较平台的差异度。公式：TA%/(KOL近三个月总读者数/小红书平台总人数)×100",
          isRank: 0,
          label: "TA TGI",
          order_id: 11,
          prop: "tarTgi",
          round: 0,
          type: "Double",
          width: 50,
        },
      ],
      bilibiliColData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "类型",
          order_id: 3,
          prop: "category",
          round: null,
          type: "String",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月观看去重总人数",
          isRank: 1,
          label: "月总浏览UV",
          order_id: 4,
          prop: "muv",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月单篇观看人数",
          isRank: 0,
          label: "单篇浏览UV",
          order_id: 5,
          prop: "monthAvgUv",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "视频平均互动",
          isRank: 0,
          label: "月单篇互动数",
          order_id: 12,
          prop: "monthAvgAct",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月推文数量",
          isRank: 0,
          label: "月文章数量",
          order_id: 13,
          prop: "postCount",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月播放总量",
          isRank: 0,
          label: "月总播放",
          order_id: 6,
          prop: "monthPlaySum",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月视频互动总和（点赞、金币、收藏、分享、评论）",
          isRank: 0,
          label: "月总互动",
          order_id: 7,
          prop: "mact",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "账号页面显示的粉丝数",
          isRank: 0,
          label: "粉丝数",
          order_id: 8,
          prop: "kolFans",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月对所选人群的覆盖度。公式：人群在KOL中匹配人数除以人群总数×100%",
          isRank: 0,
          label: "TA%",
          order_id: 10,
          prop: "tarPct",
          round: 2,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月对所选人群的覆盖度相较平台的差异性。公式：Ta%/(KOL近三个月总读者数/哔哩哔哩平台总人数)×100",
          isRank: 0,
          label: "TA TGI",
          order_id: 11,
          prop: "tarTgi",
          round: 0,
          type: "Double",
          width: 50,
        },
      ],
      kuaishouColData: [
        {
          canRank: 0,
          color: null,
          icon: null,
          info: null,
          isRank: 0,
          label: "类型",
          order_id: 3,
          prop: "category",
          round: null,
          type: "String",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月视频去重去水曝光总人数",
          isRank: 1,
          label: "月浏览UV",
          order_id: 4,
          prop: "muv",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月视频转评赞总人数",
          isRank: 0,
          label: "月总互动",
          order_id: 5,
          prop: "mact",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月视频去水后单篇浏览人数",
          isRank: 0,
          label: "月单篇曝光",
          order_id: 12,
          prop: "postExposure",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "视频平均互动",
          isRank: 0,
          label: "视频互动",
          order_id: 13,
          prop: "postAct",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "当月发布文章数量",
          isRank: 0,
          label: "月发布篇数",
          order_id: 6,
          prop: "postCount",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "账号页面显示的粉丝数",
          isRank: 0,
          label: "粉丝数",
          order_id: 8,
          prop: "kolFans",
          round: null,
          type: "Integer",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月对所选人群的覆盖度。公式：人群在KOL中匹配人数除以人群总数×100%",
          isRank: 0,
          label: "TA%",
          order_id: 10,
          prop: "tarPct",
          round: 2,
          type: "Percent",
          width: 50,
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          info: "KOL近三个月对所选人群的覆盖度相较平台的差异性。公式：Ta%/(KOL近三个月总读者数/哔哩哔哩平台总人数)×100",
          isRank: 0,
          label: "TA TGI",
          order_id: 11,
          prop: "tarTgi",
          round: 0,
          type: "Double",
          width: 50,
        },
      ],
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    showHandle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["siteValue", "isHighLight", "isExpand", "colSiteValue"]),
    expandFunc() {
      if (!this.isExpand) {
        return [];
      } else {
        return this.tableData.map((v) => {
          return v.mKolid;
        });
      }
    },
    priceBySite() {
      switch (this.site) {
        case "wechat":
          return "价格（一推）";
        case "weibo":
          return "价格（直发）";
        case "douyin":
          return "价格（视频21-60s）";
        case "xiaohongshu":
          return "价格（图文笔记）";
        case "bilibili":
          return "价格（视频定制）";
        case "kuaishou":
          return "价格（原创视频）";
        default:
          return "价格";
      }
    },
    site() {
      return this.$route.name === "RankingListV2"
        ? this.siteValue
        : this.colSiteValue;
    },
  },
  mounted() {
    if (this.$route.path.includes("/campaign")) {
      this.tableHeight = document.body.clientHeight - 550;
    } else if (this.$route.path !== "/rankingListV2") {
      this.tableHeight = document.body.clientHeight - 425;
    } else {
      this.tableHeight = document.body.clientHeight - 310;
    }
  },
  methods: {
    ...mapMutations({
      setIsHighLight: "SET_ISHIGHLIGHT",
    }),
    setWidthByIndex(item) {
      if (item.label === "TA TGI") {
        return 55;
      } else if (item.label === "类型" || item.label === "级别") {
        return 38;
      } else {
        return null;
      }
    },
    sortClick(value, label) {
      if (!this.isHighLight) {
        this.setIsHighLight(true);
      }
      this.rankCol = value;
      this.choseColData();
      this.$emit("changeSort", value, label);
    },
    nameClick(row) {
      // 改为弹窗模式，就向上广播
      this.$emit("openDetail", row.mKolid, row.name, "1");
    },
    selectChange(item, row) {
      if (this.site === "weibo") {
        let boolVal = item.some((v) => {
          return v.mact === 0 || v.mact === undefined;
        });
        this.$emit("checkEmpty", boolVal);
      } else if (this.site === "douyin") {
        this.$emit(
          "checkEmpty",
          item.some((v) => {
            return v.muvFans === 0 || v.muvFans === undefined;
          })
        );
      } else {
        this.$emit(
          "checkEmpty",
          item.some((v) => {
            return v.muv === 0 || v.muv === undefined;
          })
        );
      }

      if (item.length > this.limit) {
        this.$refs.multipleTable.toggleRowSelection(row);
        this.checkBoxList = item.slice(0, this.limit);
        this.checkboxValueChange(true);
      } else {
        this.checkBoxList = item;
        this.checkboxValueChange();
      }
    },
    selectAll(item) {
      if (this.site === "weibo") {
        this.$emit(
          "checkEmpty",
          item.some((v) => {
            return v.mact === 0 || v.mact === undefined;
          })
        );
      } else if (this.site === "douyin") {
        this.$emit(
          "checkEmpty",
          item.some((v) => {
            return v.muvFans === 0 || v.muvFans === undefined;
          })
        );
      } else {
        this.$emit(
          "checkEmpty",
          item.some((v) => {
            return v.muv === 0 || v.muv === undefined;
          })
        );
      }
      this.$refs.multipleTable.clearSelection();
      if (this.checkBoxList.length === this.limit) {
        this.checkBoxList = [];
      } else {
        this.checkBoxList = item.slice(0, this.limit);
        this.checkBoxList.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item, true);
        });
      }
      this.checkboxValueChange();
    },
    checkboxValueChange(bool) {
      if (bool) {
        this.$emit("checkboxValueChange", this.checkBoxList, bool);
      } else {
        this.$emit("checkboxValueChange", this.checkBoxList);
      }
    },
    choseColData() {
      const colData = this.site + "ColData";
      this.handleIsRank(this[colData]);
      this.colData = this[colData];
    },
    handleIsRank(colData) {
      colData.forEach((v) => {
        v.prop === this.rankCol ? (v.isRank = 1) : (v.isRank = 0);
      });
    },
    openDelKolDialog(id) {
      this.$emit("openDelKolDialog", id);
    },
  },
  watch: {
    tableData() {
      this.showTable = false;
      this.tableData.forEach((v) => {
        v.fakePostPct = v.fakePostPct === null ? "--" : v.fakePostPct;
      });
      this.choseColData();
      setTimeout(() => {
        this.showTable = true;
      }, 20);
      const len = this.checkBoxList.length;
      if (len) {
        setTimeout(() => {
          for (let item of this.checkBoxList) {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        }, 50);
      }
    },
    site: {
      handler() {
        switch (this.site) {
          case "weibo":
            this.rankCol = "mact";
            break;
          default:
            this.rankCol = "muv";
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.priceSpan {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
}

.green {
  background-color: #67c23a;
}

.yellow {
  background-color: #e6a23c;
}

.priceGreen {
  color: #67c23a;
}

.priceYellow {
  color: #e6a23c;
}

.name {
  cursor: pointer;
  margin-left: 6px;

  &:hover {
    color: orange;
  }
}
</style>
