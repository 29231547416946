import request, {
  applicationJson,
  downloadFile,
  downloadLocalFile,
} from "@/utils/axios";

export function creatCollect(params) {
  return request.post("/api/user/collection", params);
}

export function delCollect(id) {
  return request.delete("/api/user/collection", { id });
}

export function getUserCollect(params) {
  return request.get("/api/user/collection", params);
}

export function getHaveKol(params) {
  return applicationJson.post("/api/user/kol", params);
}

export function getHaveKolV2(params) {
  return applicationJson.post("/collection/kol2", params);
}

export function delHaveKol(params) {
  return request.delete("/api/user/kol", params);
}

export function getNoKol(params) {
  return request.get("/api/user/nonkol", params);
}

export function searchHaveKol(params) {
  return request.get("/api/user/kol/search", params);
}

export function submitKol(params) {
  return applicationJson.post("/api/user/kol/list", params);
}

export function addAllKol(params) {
  return request.get("/campaign/collection/kol", params);
}

export function fileAddKol(params) {
  return request.post("/api/user/kol/file", params);
}

export function addFileSubmit(params) {
  return applicationJson.post("/api/user/kol/file/submission", params);
}

export function getAmount() {
  return request.get("/api/user/kol/amount");
}

export function getCollectionInfo(params) {
  return request.get("/api/user/collection/info", params);
}

export function setCrowd(params) {
  return request.put("/api/user/collection/crowd", params);
}

export function downloadCrowd(params, fileName) {
  return downloadFile("/getdata/getFrontSideData", params, fileName);
}

export function downloadCrowdV2(params, fileName) {
  return downloadFile("/collection/kol/report", params, fileName);
}

export function downloadKolUploadTemplate() {
  return downloadLocalFile("./fileTemplate_20220414.xlsx", "fileTemplate.xlsx");
}

export function downloadKolFile() {
  return downloadLocalFile(
    "./omniVoice_manualForAddKol_20220328.pdf",
    "omniVoice_manualForAddKol.pdf"
  );
}

export function downloadKolStatus(params, fileName) {
  return downloadFile(
    "./api/user/collection/nonkol/status/file",
    params,
    fileName
  );
}

// downloadFile
export function downloadKolLabelTemplate(params, fileName) {
  return downloadFile("/kol/label/file", params, fileName);
}

// downloadPriceTemplate
export function downloadPriceTemplate(params, fileName) {
  return downloadFile("/collection/kol/price", params, fileName);
}

// submitFileUpload
export function submitFileUpload(params) {
  return request.post("/kol/label/file/submission", params);
}

// submitPriceUpload
export function submitPriceUpload(params) {
  return request.post("/collection/kol/price/submission", params);
}
