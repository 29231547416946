<template>
  <div class="leftContainer">
    <p class="leftTitle">
      人群画像
      <span
        v-for="(item, index) in legendArr"
        :key="index"
        style="font-size: 12px; color: #666; margin-left: 20px"
      >
        <i
          style="
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid #eee;
            vertical-align: sub;
          "
          :style="{ backgroundColor: item.color }"
        ></i>
        {{ item.name === "占比" ? item.name + " (%)" : item.name }}
      </span>
    </p>
    <el-row type="flex">
      <el-col :span="12">
        <div class="annularPieContainer">
          <!-- 性别分布 & 城市分布 -->
          <div class="citySex" style="width: 100%">
            <div style="flex: 3">
              <sexPie :seriesData="genderData"></sexPie>
            </div>
            <div style="flex: 5">
              <double-y-bar
                style="height: 250px"
                :seriesData="cityData"
                :title="'城市占比'"
              />
            </div>
          </div>

          <double-y-bar
            style="height: 250px"
            :seriesData="incomeSeriesData"
            :title="'收入水平'"
          />
        </div>
      </el-col>
      <el-col :span="12">
        <div class="annularPieContainer">
          <double-y-bar
            style="height: 250px"
            :seriesData="ageData"
            :title="'年龄分布'"
          />
          <y-bar
              v-show="eightData && eightData.xaxias && eightData.xaxias.length > 0"
              style="height: 250px;width:697px"
              :seriesData="eightData"
              :title="'八大人群'"
          />
          <double-y-bar
              v-show="eightData && eightData.xaxias && eightData.xaxias.length <= 0"
            style="height: 250px;width:697px"
            :seriesData="appTopData"
            :title="'兴趣偏好'"
          />
        </div>
      </el-col>
      <el-col :span="12"  v-show="eightData && eightData.xaxias && eightData.xaxias.length > 0">
        <div class="annularPieContainer">
          <double-y-bar
              style="height: 250px;width:697px"
              :seriesData="appTopData"
              :title="'兴趣偏好'"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/*
 * 在kolchosen中把整个数据传递过来
 * 在kolfeature中mounted的时候要发送请求
 * */
import doubleYBar from "@/components/echarts/doubleYBar";
import sexPie from "./commonComponents/sexPie.vue";
import yBar from "@/components/echarts/yBar";
export default {
  name: "portrayal",
  data() {
    return {
      legendArr: [
        {
          name: "占比",
          color: "#51A6f0",
        },
        {
          name: "TGI",
          color: "#A8C9E9",
        },
      ],
      ageTitle: "年龄",
      ageData: {},
      ageColor: [],
      ageLegend: [],
      genderTitle: "性别",
      genderData: {},
      genderColor: [],
      genderLegend: [],
      cityTitle: "城市",
      cityData: {},
      cityColor: [],
      cityLegend: [],
      incomeSeriesData: {},
      appTopData: {},
      eightData:{}
    };
  },
  props: {
    userProfileData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    userProfileData: {
      handler(val) {
        let tempObj = {};
        tempObj = JSON.parse(JSON.stringify(val.age));
        // 对占比数据进行乘100 加工
        tempObj.series1 = tempObj.series1.map((v) => v * 100);
        this.ageData = tempObj;
        console.log(this.ageData)
        // gender数据不用处理
        this.genderData = val.gender;
        // tempObj = val.income;
        tempObj = JSON.parse(JSON.stringify(val.income));
        tempObj.series1 = tempObj.series1.map((v) => v * 100);
        this.incomeSeriesData = tempObj;
        // tempObj = val.topDiv;
        tempObj = JSON.parse(JSON.stringify(val.topDiv));
        tempObj.series1 = tempObj.series1.map((v) => v * 100);
        this.appTopData = tempObj;
        //
        tempObj = JSON.parse(JSON.stringify(val.city));
        tempObj.series1 = tempObj.series1.map((v) => v - 0);
        tempObj.series2 = tempObj.series2.map((v) => v - 0);
        this.cityData = tempObj;
        tempObj = JSON.parse(JSON.stringify(val.ta));
        tempObj.series1 = tempObj.series1.map((v) => v - 0);
        this.eightData = tempObj;
        console.log(this.eightData)

      },
      deep: true,
    },
  },
  components: {
    doubleYBar,
    sexPie,
    yBar
  },
};
</script>

<style scoped lang="scss">
.leftContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  .leftTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: $font-size-medium-x;
    border-bottom: 1px solid $color-title-underline;
  }

  .annularPieContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    .citySex {
      display: flex;
    }
  }
}
</style>
