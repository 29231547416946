<template>
  <div class="plateform_block">
    <p class="title_block">
      社媒平台
    </p>
    <el-menu :default-active="chosenSite" @select="siteValueChange" v-if="$route.name === 'AmateurRankingList'">
      <el-menu-item
          v-for="item in amateurSiteValue"
          :key="item.value"
          :index="item.value"
          :disabled="isDisabled"
          :style="
          item.value === chosenSite
            ? { borderBottom: '4px solid #4F79BF'}
            : { }
        "
          :class="size === 'normal' ? 'normal_class' : 'mini_class'"
      >
        <svg-icon
            :class-name="item.value"
            class="icon"
            :class="item.value === chosenSite ? 'icon_active' : 'icon_normal'"
        />
        {{ item.label }}
        <span v-if="showKolNumber">({{ kolNumberData[item.value] }})</span>
      </el-menu-item>
    </el-menu>
    <el-menu :default-active="chosenSite" @select="siteValueChange" v-else>
      <el-menu-item
        v-for="item in sites"
        :key="item.value"
        :index="item.value"
        :style="
          item.value === chosenSite
            ? { borderBottom: '4px solid #4F79BF'}
            : { }
        "
        :class="size === 'normal' ? 'normal_class' : 'mini_class'"
      >
        <svg-icon
          :class-name="item.value"
          class="icon"
          :class="item.value === chosenSite ? 'icon_active' : 'icon_normal'"
        />
        {{ item.label }}
        <span v-if="showKolNumber">({{ kolNumberData[item.value] }})</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "sidebar",
  computed: {
    ...mapGetters(["sites", "siteValue", "colSiteValue", "amateurSiteValue"]),
    chosenSite() {
      if(this.$route.name === "RankingListV2" || this.$route.name === 'AmateurRankingList'){
        return this.siteValue;
      }else{
        return this.colSiteValue;
      }
    },
  },
  beforeUnmount() {
    this.siteValueChange("wechat");
  },
  props: {
    showKolNumber: {
      type: Boolean,
      default: false,
    },
    kolNumberData: {
      type: Object,
      default() {
        return {
          wechat: 0,
        };
      },
    },
    size: {
      type: String,
      default: "normal",
    },
  },
  methods: {
    ...mapMutations({
      setSiteValue: "SET_SITEVALUE",
      setColSiteValue: "SET_COLSITEVALUE",
    }),
    siteValueChange(value) {
      if (this.$route.name === "RankingListV2"|| this.$route.name === 'AmateurRankingList') {
        this.setSiteValue(value);
      } else {
        this.setColSiteValue(value);
      }
    },
  }
};
</script>

<style scoped lang="scss">
.el-menu {
  border-right: none;

  .el-menu-item {
    display: flex;
    align-items: center;
  }

  .el-menu-item.is-active {
    color:#4f79bf!important;
  }

  .normal_class {
    font-size: 14px;
    padding-right: 10px;

    .icon {
      font-size: 24px;
    }
  }

  .mini_class {
    font-size: 12px;
    padding-right: 0;

    .icon {
      font-size: 14px;
      margin-right: 6px;
    }
  }
}

.icon_normal {
  fill: #909399;
}

.icon_active {
  fill: #4f79bf;
}
</style>
<style lang="scss">
.plateform_block{
  width: 130px;
  height:100%;
  border-radius:10px;
  background:#fff url('../../assets/images/platform_bg.png') center bottom no-repeat;
  background-size:108px 84px;
  .title_block{
    height: 62px;
    line-height: 62px;
    background: url("../../assets/images/line_bg.png") bottom center no-repeat;
    background-size:108px 1px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color:#222;
    text-align: center;
  }
  .el-menu{
    display: flex;
    flex-direction: column;
    gap:30px;
    padding:20px 0;
    align-items: center;
    justify-content: center;
  }
  .el-menu-item{
    display: flex;
    flex-direction: column;
    height:auto;
    line-height: normal;
    font-size:18px!important;
    font-family: Source Han Sans CN;
    gap:5px;
    width:24px;
    padding:0 0 2px 0!important;
    border-bottom:4px solid #fff;
  }
  .el-menu-item:hover{
    background: #fff!important;
  }
}
</style>