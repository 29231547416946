<template>
  <div class="filter">
    <!--目标人群-->
    <crowed-com />
    <div class="btnGroup">
      <!--收起/展开-->
<!--      <el-button-->
<!--        type="text"-->
<!--        class="noborderBtn"-->
<!--        :icon="!isExpand ? 'el-icon-plus' : 'el-icon-minus'"-->
<!--        @click="expandAll"-->
<!--        size="mini"-->
<!--        >{{ isExpand ? "全部收起" : "全部展开" }}-->
<!--      </el-button>-->
      <!--排序方式-->
      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="order"
        @change="handleOrder"
        class="autoWidth"
        placeholder="请选择排序方式"
      >
        <template #prefix v-if="order !== ''">
          {{ order.includes('降序')?order:orderOptions.filter(item =>item.value === order)[0].label}}
        </template>
        <el-option
          v-for="(item, i) in orderOptions"
          :key="item.value"
          :class="
            i % 2 !== 0 ? (i === orderOptions.length - 1 ? '' : 'myOption') : ''
          "
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!--常用达人：加入活动-->
      <el-button
        v-show="addActivity"
        style="margin-right: 20px"
        @click="addToActivity"
        type="primary"
        size="mini"
        >加入活动
      </el-button>
      <!--常用达人：前侧数据下载-->
      <el-button
        v-show="downloadCrowdFlag"
        style="margin-right: 20px"
        :loading="downLoading"
        @click="downloadCrowd"
        type="primary"
        size="mini"
        >KOL前测数据下载
      </el-button>
      <!--榜单1.0/2.0切换-->
      <list-version v-if="showListVersion" />
    </div>
  </div>
</template>

<script>
import crowedCom from "./crowedCom/crowedCom";
import { mapGetters, mapMutations } from "vuex";
import { downloadCrowd, downloadCrowdV2 } from "@/api/collection";
import listVersion from "@/components/common/listVersion";

export default {
  name: "filterCom",
  data() {
    return {
      downLoading: false,
      order: "",
    };
  },
  props: {
    colEmpty: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: String,
      default: "",
    },
    showListVersion: {
      type: Boolean,
      default: true,
    },
    collectionId: {
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      "isExpand",
      "siteValue",
      "month",
      "colTargetCrowd",
      "colSiteValue",
      "colCategorySeg",
      "listVersion",
      "listSortLabel",
    ]),
    orderOptions() {
      if (this.listVersion === 1) {
        return [
          {
            label: "ER升序排列",
            value: "engagementRate_1",
          },
          {
            label: "ER降序排列",
            value: "engagementRate_0",
          },
          {
            label: "CPE升序排列",
            value: "cpe_1",
          },
          {
            label: "CPE降序排列",
            value: "cpe_0",
          },
          {
            label: "CPUV升序排列",
            value: "cpi_1",
          },
          {
            label: "CPUV降序排列",
            value: "cpi_0",
          },
          {
            label: "CPTA升序排列",
            value: "cpta_1",
          },
          {
            label: "CPTA降序排列",
            value: "cpta_0",
          },
          {
            label: "价格升序排列",
            value: "price_1",
          },
          {
            label: "价格降序排列",
            value: "price_0",
          },
        ];
      } else if (this.site === "weibo") {
        return [
          {
            label: "CPE升序排列",
            value: "cpe_1",
          },
          {
            label: "CPE降序排列",
            value: "cpe_0",
          },
          {
            label: "价格升序排列",
            value: "price_1",
          },
          {
            label: "价格降序排列",
            value: "price_0",
          },
        ];
      } else {
        return [
          {
            label: "ER升序排列",
            value: "engagementRate_1",
          },
          {
            label: "ER降序排列",
            value: "engagementRate_0",
          },
          {
            label: "CPE升序排列",
            value: "cpe_1",
          },
          {
            label: "CPE降序排列",
            value: "cpe_0",
          },
          {
            label: "CPI升序排列",
            value: "cpi_1",
          },
          {
            label: "CPI降序排列",
            value: "cpi_0",
          },
          {
            label: "CPTA升序排列",
            value: "cpta_1",
          },
          {
            label: "CPTA降序排列",
            value: "cpta_0",
          },
          {
            label: "价格升序排列",
            value: "price_1",
          },
          {
            label: "价格降序排列",
            value: "price_0",
          },
        ];
      }
    },
    // 追加kol
    downloadCrowdFlag() {
      return this.$route.name !== "RankingListV2";
    },
    addActivity() {
      return (
        this.$route.name !== "RankingListV2" &&
        this.$route.name !== "CollectDetail"
      );
    },
    site() {
      return this.$route.name === "RankingListV2"
        ? this.siteValue
        : this.colSiteValue;
    },
  },
  mounted() {
    this.setIsExpand(false);
    this.judgeOrder();
  },
  methods: {
    ...mapMutations({
      setIsExpand: "SET_ISEXPAND",
      setOrderRule: "SET_ORDERRULE",
      setColOrderRule: "SET_COLORDERRULE",
      setIsHighLight: "SET_ISHIGHLIGHT",
    }),
    handleOrder(val) {
      this.setIsHighLight(false);
      if (this.$route.name === "RankingListV2") {
        this.setOrderRule(val);
      } else {
        this.setColOrderRule(val);
      }
    },
    expandAll(event) {
      this.setIsExpand(!this.isExpand);
      event.target.parentNode.blur();
    },
    // 常用达人下载人群按钮
    downloadCrowd() {
      if (this.colEmpty) {
        this.$message({
          message: "还未添加KOL，无数据",
          type: "info",
        });
        return;
      }
      this.downLoading = true;
      this.$message({
        message: "数据正在导出，请不要刷新切换页面，耐心等待!",
        type: "info",
      });
      let params = {
        collectionId: this.collectionId,
        month: this.month,
        seg: this.colCategorySeg,
      };
      const fileName = `${this.colTargetCrowd.name}_前侧数据.xlsx`;
      if (this.listVersion) {
        params.duration = this.duration;
        downloadCrowdV2(params, fileName).then(() => {
          this.$message({
            message: "正在导出",
            type: "success",
          });
          this.downLoading = false;
        });
      } else {
        downloadCrowd(params, fileName).then(() => {
          this.$message({
            message: "正在导出",
            type: "success",
          });
          this.downLoading = false;
        });
      }
    },
    // 创建活动：加入侧边栏
    addToActivity() {
      this.$emit("addToActivity");
    },
    judgeOrder() {
      if (this.listVersion === 1) {
        this.order = this.listSortLabel;
      } else {
        switch (this.site) {
          case "wechat":
            this.order = "月总阅读UV降序";
            break;
          case "weibo":
            this.order = "月总互动降序";
            break;
          case "douyin":
            this.order = "月总曝光UV降序";
            break;
          case "xiaohongshu":
            this.order = "阅读月UV降序";
            break;
          case "bilibili":
            this.order = "月总浏览UV降序";
            break;
          case "kuaishou":
            this.order = "月浏览UV降序";
            break;
        }
      }
    },
  },
  watch: {
    site() {
      this.judgeOrder();
    },
    listVersion() {
      this.judgeOrder();
    },
    listSortLabel(val) {
      this.order = val;
    },
  },
  components: {
    crowedCom,
    listVersion,
  },
};
</script>

<style lang="scss">
.myOption {
  border-bottom: 1px solid #ccc;
}
</style>

<style scoped lang="scss">
.filter {
  height: 72px;
  line-height: 72px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btnGroup {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}
</style>
